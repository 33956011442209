import UpSetJS, { extractCombinations } from "@upsetjs/react";
import { Row, Spin } from "antd";
import { IGenes } from "../../../../report";
import { useEffect, useState } from "react";
import { upsetPlot } from "src/service/api/home";
import { THRESHOLD_MAP_DETIAL } from "../ResultPage";

export default function UpsetComponent(props: {
  genesListInfo: IGenes[];
  isOffline: boolean;
  trait: number;
  recordId: number;
  limits: typeof THRESHOLD_MAP_DETIAL;
}) {
  const {
    genesListInfo,
    recordId,
    isOffline,
    trait,
    limits: thresholds,
  } = props;
  const [setsCom, setSetsCom] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  useEffect(() => {
    let eles: any = [];
    if (isOffline) {
      let genesObj: any = {};
      genesListInfo?.forEach((item: any) => {
        genesObj[item.gene_id] = { sets: [] };
        for (let toolName of [
          "coloc",
          "ecaviar",
          "fastenloc",
          "predixcan",
          "smr",
          "fusion",
        ]) {
          if (item[toolName] > -1) {
            genesObj[item.gene_id].sets.push(toolName);
          }
        }
      });

      for (let gene in genesObj) {
        if (genesObj[gene].sets.length > 0)
          eles.push({ name: gene, sets: genesObj[gene].sets });
      }
      const obj = extractCombinations(eles, {
        type: "distinctIntersection",
      });
      setSetsCom(obj);
    } else {
      trait &&
        (async function () {
          try {
            setIsloading(true);
            eles = await upsetPlot({
              colot_record_id: recordId,
              limits: [
                thresholds.coloc,
                thresholds.ecaviar,
                thresholds.fastenloc,
                thresholds.fusion,
                thresholds.predixcan,
                thresholds.smr,
              ],
            });
            const obj = extractCombinations(eles?.data?.data, {
              type: "distinctIntersection",
            });
            console.log(eles)
            setSetsCom(obj);
            setIsloading(false);
          } catch (error) {
            setIsloading(false);
          }
        })();
    }
  }, [
    genesListInfo,
    isOffline,
    trait,
    thresholds.coloc,
    thresholds.ecaviar,
    thresholds.fastenloc,
    thresholds.predixcan,
    thresholds.smr,
    thresholds.fusion,
    recordId,
  ]);

  const { sets, combinations } = setsCom || {};

  return (
    <div className="upsetSpin">
      <Spin tip="Loading..." spinning={isLoading} style={{ height: "auto" }}>
        <Row className="tissue-row-title juxtify-content-center font-weight-format" style={{fontSize:'150%'}}>
          Upset Plot
        </Row>
        <Row
          style={{ height: "400px" }}
          className="tissue-row-title juxtify-content-center"
        >
          {sets?.length > 0 && (
            <UpSetJS
              {...props}
              sets={sets}
              onHover={() => {}}
              combinations={combinations}
              width={800}
              height={400}
            />
          )}
        </Row>
      </Spin>
    </div>
  );
}
