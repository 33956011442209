import styled, { css } from "styled-components";
import { Alert, Button, Col, Form, Row, Spin, message } from "antd";
import Input from "src/components/common/Input";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import CountDownButton from "src/components/login/CountDownButton";
import { LOGIN_PASSWORD_PATTERN } from "src/constants/regexp";
import { useCallback, useState } from "react";
import loginApis from "src/service/api/login";
interface LoginComponentProps {}
type FormDataType = {
  username: string;
  password: string;
};

const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const FormContainer = styled.div`
  width: 400px;
  background-color: #fff;
  padding: 28px;
  border-radius: 10px;
  margin: auto;
`;
const FormHeader = styled.h1`
  text-align: center;
  font-size: 32px;
  margin-bottom: 64px;
`;
const FormDiv = styled(Form)`
  width: 368px;
`;
const ForgetButtonFormItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const InputFormItem = styled(Form.Item)`
  .ant-form-item-explain {
    padding: 2px 0 8px 0;
  }
`;

const COUNT_DOWN_SECONDS = 5 * 60;
function ForgotPasswordComponent(props: LoginComponentProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState<boolean>(false);

  const handleFinish = useCallback(async () => {
    try {
      await form.validateFields();
    } catch {
      return;
    }
  }, [form]);

  const handleGetCaptchaClick = useCallback(async () => {
    const values = await form.validateFields(["email"]);
    await loginApis.getCode(values.email);
  }, [form]);

  const submitHandle = useCallback(async () => {
    try {
      setIsloading(true);
      const values = await form.validateFields();
      const rep = await loginApis.forgot(values);
      if (rep.status === 200 && rep.data.code === 200) {
        message.success("reset password success!");
        navigate("/login");
      } else {
        message.error(rep.data.data || rep.data.msg);
      }
      setIsloading(false);
    } catch (e) {
      setIsloading(false);
      console.log(e);
    }
  }, [form, navigate]);

  return (
    <Spin spinning={isLoading} tip="Loading...">
      <RightContainer>
        <FormContainer>
          <FormHeader>Reset Password</FormHeader>
          <FormDiv
            labelCol={{
              span: 0,
            }}
            onFinish={handleFinish}
            onFinishFailed={() => {}}
            autoComplete="off"
            validateTrigger={false}
            form={form}
          >
            <InputFormItem
              name="email"
              rules={[
                { required: true },
                {
                  type: "email",
                  message: "",
                },
              ]}
            >
              <Input placeholder="Please enter a valid email." />
            </InputFormItem>
            <InputFormItem name="code" rules={[{ required: true }]}>
              <Row>
                <Col flex="1">
                  <Form.Item
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Please input the captcha you got!",
                      },
                    ]}
                    name="code"
                  >
                    <Input placeholder="Please enter email captcha." />
                  </Form.Item>
                </Col>
                <Col flex="none" offset={1}>
                  <CountDownButton
                    number={COUNT_DOWN_SECONDS}
                    loading={false}
                    disabled={false}
                    onClick={handleGetCaptchaClick}
                  >
                    {(isCountDown, countDownNumber) => {
                      return isCountDown
                        ? `Resend after ${countDownNumber} seconds`
                        : "Get captcha";
                    }}
                  </CountDownButton>
                </Col>
              </Row>
            </InputFormItem>
            <InputFormItem name="new_password" rules={[{ required: true }]}>
              <Input
                autoComplete="new-password"
                type="password"
                placeholder="Please enter password"
              />
            </InputFormItem>

            <ForgetButtonFormItem>
              <Button
                style={{ width: "700px" }}
                type="primary"
                htmlType="submit"
                size="large"
                block
                onClick={submitHandle}
              >
                submit
              </Button>
              <Button
                type="link"
                style={{ fontSize: "14px", textAlign: "right" }}
                onClick={() => navigate("/login")}
                size="large"
                block
              >
                Back to login
              </Button>
            </ForgetButtonFormItem>
          </FormDiv>
        </FormContainer>
      </RightContainer>
    </Spin>
  );
}

export default ForgotPasswordComponent;
