import { SortOrder } from "antd/lib/table/interface";
import request from "../request";
import axios from "axios";

export interface ITask {
  sub_tasks: ISubTask[];
  task_id: number;
  // progress: number;
}
export interface ISubTask {
  eqtl_id: number;
  record_id: number;
  tissue: string;
  status: string;
  progress: number;
}

export type AppResponse<T> = {
  data: T | any;
  code?: number;
  msg?: string;
};

export interface IColocConfigRequest {
  gwas_params:
    | {
        trait: string;
        type: string;
        pval_threshold: string;
        sample_size: string;
        rs_id: string;
        chrom: string;
        position: string;
        beta: string;
        effect_allele: string;
        other_allele: string;
        p_value: string;
        standard_error: string;
      }
    | any;
  tool_params:
    | {
        a1: string;
        a2: string;
        p1: string;
        p2: string;
        p12: string;
      }
    | any;
  gwas_raw_id: string;
  tissues: string[] | any;
  tools: string[] | any;
}

export type ColocConfigResponse = {
  taskId: string;
  gwas_file: string;
  population: string;
  submitt_at: string;
  duration: string;
} & IColocConfigRequest;

export interface LinearPlotResponse {
  data: [string, number, number, number, number][];
  target: string;
  line: {
    slope: number;
    intercept: number;
  };
}

export interface IHeatmapListResponse {
  task_id: number;
  limits: any;
  order_by_tissue: string;
  order_by: string;
  order: string;
  page: number;
  page_size: number;
}
export interface IUpsetList {
  colot_record_id: number;
  limits: any;
  order_by: string;
  order: string;
  page?: number;
  page_size?: number;
}
export type SortableReq = {
  orderBy: string;
  order: "DESC" | "ASC";
};

export interface IManHattan {
  chrom: number;
  p_value: number;
  position: number;
  snp: string;
}
export function mapAntdOrderToServer(
  order: NonNullable<SortOrder>
): SortableReq["order"] {
  const map: Record<typeof order, SortableReq["order"]> = {
    descend: "DESC",
    ascend: "ASC",
  };
  return map[order];
}
export async function taskList() {
  return await request.post<AppResponse<ISubTask[][]>>(`/api/v1/task_list`);
}
export async function gwasFile(data: { file: any; onUploadProgress: any }) {
  const { onUploadProgress, file } = data;

  const buffer = await file.arrayBuffer();
  // 只有https才能使用crypto.subtle
  const hash = await crypto.subtle.digest("SHA-256", buffer);
  const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  const res = await request.post<
    AppResponse<{
      gwas_file_exist: boolean;
      gwas_raw_id: number;
      upload_to: string;
    }>
  >(`/api/v1/get_upload_url`, { sha256: hashHex, filename: data.file.name });
  if (!res.data.data.gwas_file_exist) {
    const response = await axios.put(res.data.data.upload_to, data.file, {
      headers: {
        // "x-oss-object-acl": "public-read",
        "Content-Type": "",
      },
      onUploadProgress,
    });
  }
  return res.data.data;

  // const formData = new FormData();
  // formData.append("file", data.file);
  // return await request.post<AppResponse<{ url: string }>>(
  //   `/api/v1/gwas_file`,
  //   formData,
  //   { onUploadProgress }
  // );
}
export async function check_gwas(data: { s3_key : any, chrom : any, snp : any, ref: any, alt:any}) {
  const { s3_key, chrom, snp, ref, alt } = data;

  const res = await request.post<
    AppResponse<{
      checkformat: number;
      extra_info: string
    }>
  >(`/api/v1/gwas_check`, { s3_key: s3_key, chrom: chrom, snp: snp, ref: ref, alt: alt});
  
  return res.data.data;

  // const formData = new FormData();
  // formData.append("file", data.file);
  // return await request.post<AppResponse<{ url: string }>>(
  //   `/api/v1/gwas_file`,
  //   formData,
  //   { onUploadProgress }
  // );
}
export async function colocConfig(data: IColocConfigRequest) {
  return await request.post<AppResponse<{ taskId: string }>>(
    `/api/v1/coloc_config`,
    data
  );
}
export async function getColocConfig(taskId: number) {
  return await request.post<AppResponse<ColocConfigResponse>>(
    `/api/v1/task_detail`,
    { task_id: taskId }
  );
}
export async function eqtlList() {
  return await request.get<AppResponse<{ [key in string]: number }[]>>(
    `/api/v1/coloc_config/eqtl_list`
  );
}
export async function manhattanPlot(colot_record_id: number) {
  return await request.post<AppResponse<IManHattan[]>>(
    `/api/v1/manhattan_plot`,
    {
      colot_record_id,
    }
  );
}
export async function qqPlot(colot_record_id: any) {
  return await request.post<AppResponse<any>>(`/api/v1/qq_plot`, {
    colot_record_id,
  });
}
export async function heatmapList(data: IHeatmapListResponse) {
  return await request.post<
    AppResponse<{ list: { [key in string]: number }[] }>
  >(`/api/v1/heatmap`, data);
}
export async function heatmapHover(data: {
  task_id: string;
  gene_id: string;
  tissue: string;
}) {
  return await request.post<AppResponse<{ [key in string]: number }[]>>(
    `/api/v1/heatmap_hover`,
    data
  );
}
export async function upsetPlot(data: {
  colot_record_id: number;
  limits: number[];
}) {
  return await request.post<AppResponse<{ gene_id: string; sets: string[] }[]>>(
    `/api/v1/upset_plot`,
    data
  );
}
export async function upsetList(data: IUpsetList) {
  return await request.post<
    AppResponse<
      {
        coloc: string | number;
        ecaviar: string | number;
        fastenloc: string | number;
        intact: string | number;
        name: string;
        predixcan: string | number;
        rank: string | number;
        smr: string | number;
        fusion: string | number;
      }[]
    >
  >(`/api/v1/upset_list`, data);
}

export async function geneListPlot(data: {
  colot_record_id: string;
  tool: string;
}) {
  return await request.post<
    AppResponse<{ chrom: number; gene_id: string; tool_result: number }[]>
  >(`/api/v1/locuscompare/gene_list`, data);
}

export async function geneDetail(data: {
  gene_id: string;
  colot_record_id: string;
}) {
  if (data === undefined ||
    data.gene_id === undefined ||
    data.colot_record_id === undefined
  ) { return; }
  return await request.post<
    AppResponse<
      {
        chrom: number;
        coloc: number;
        ecaviar: number;
        gene_id: string;
        gene_symbol: string;
        predixcan: number;
        smr: number;
        tss: string;
        fusion: number;
      }[]
    >
  >(`/api/v1/locuscompare/gene_detail`, data);
}
export async function locusDatas(data: {
  gene_id: string;
  colot_record_id: string;
  snp: string;
  population: string;
}) {
  return await request.post<
    AppResponse<
      {
        eqtl_value: number;
        gwas_value: number;
        position: number;
        r2: number;
        rsid: string;
      }[]
    >
  >(`/api/v1/locuscompare/data_list`, data);
}
export async function snpList(data: {
  gene_id: string;
  colot_record_id: string;
  population?: string;
}) {
  console.log("/api/v1/locuscompare/snp_list - data", data)
  return await request.post<AppResponse<{ list: string[]; max: string }>>(
    `/api/v1/locuscompare/snp_list`,
    data
  );
}
export async function snpDetail(data: {
  colot_record_id: string;
  rs_id: string;
}) {
  return await request.post<
    AppResponse<{
      chrom: number;
      position: number;
      rs_id: string;
      reference_snp: string;
      alternate_snp: string;
      allele_frequency: number;
      afr_frequency: number;
      amr_frequency: number;
      eas_frequency: number;
      eur_frequency: number;
      sas_frequency: number;
    }>
  >(`/api/v1/locuscompare/snp_detail`, data);
}

export async function getGenomicLoci(taskId: number) {
  if (taskId===undefined ) { return; }
  return await request.post<
    AppResponse<
      {
        end: number;
        start: number;
        lead_snp: number;
        total_snp: number;
      }[]
    >
  >(`/api/v1/loci_list`, { task_id: taskId });
}

export async function getTpmList(data: {
  page: number;
  page_size: number;
  gene_id: string;
}) {
  return await request.post<
    AppResponse<
      {
        description: string;
        gene_id: string;
        tpm_id: string;
        value: number;
      }[]
    >
  >(`/api/v1/tpm_list2`, data);
}

// export async function getEqtlChart(data: {
//   colot_record_id: number;
//   // count: number;
//   gene_id: string;
// }) {
//   return await request.post<
//     AppResponse<LinearPlotResponse>
//   >(`/api/v1/linear_plot`, data);
// }
export async function getEqtlChart(data: {
  gene_id: string;
  colot_record_id: string;
  snp: string;
  population: string;
}) {
  return await request.post<
    AppResponse<
      {
        eqtl_value: number;
        gwas_value: number;
        position: number;
        eqtl_se: number;
        gwas_se: number;
        r2: number;
        rsid: string;
        slope: number;
        intercept: number;
      }[]
    >
  >(`/api/v1/linear_plot`, data);
}