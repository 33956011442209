import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import * as echarts from "echarts";
import svglogo from 'src/svg_logo.jpg';

interface Props {
  dataList: any[];
  option: {
    width?: number;
    height?: number;
    xName?: string;
    yName?: string;
    yMax?: number;
    xAxis?: any;
    yNameGap?: any;
    name?: any;
    style?: any;
    symbolSize?: any;
    visualMap?: any;
    series?: any;
  };
  tooltip?: any;
  clickCallback?: any;
}

const coloc_map: any = {
  target: "rgb(150, 50, 184)",
  single_chr: "rgb(175, 175, 175)",
  double_chr: "rgb(0, 123, 255)",
  r2_five: "#f50703",
  r2_four: "#f8a402",
  r2_thr: "#186400",
  r2_two: "#87ceeb",
  r2_one: "#0c028b",
};
export default React.memo(
  function ScatterPlotComponent(props: Props) {
    const {
      dataList,
      option = {
        width: 500,
        height: 500,
        xName: "",
        yName: "",
        xAxis: null,
        yMax: 30,
        yNameGap: 30,
        symbolSize: 8,
        name: "",
        style: {},
        visualMap: null,
        series: [],
      },
      tooltip = {},
      clickCallback,
    } = props;
    const chartRef = useRef<any>(null);

    useEffect(() => {
      let chartDom = chartRef.current;
      let myChart = echarts.init(chartDom, "", { renderer: "svg" });
      const curOption: any = {
        title: { text: option.name, left: "center" },
        toolbox: {
          right: 0,
          bottom: 0,
          itemSize:50,
          feature: {
              saveAsImage: {
                show: true,
                icon: `image://${svglogo}`,
                name: 'locuscompareplot',
              },
          },
        }, //图片下载功能
        tooltip,
        xAxis: option.xAxis || {
          name: option.xName,
          scale: true,
          type: "value",
          nameLocation: "middle",
          nameGap: 30,
          splitLine: {
            show: false, // 设置为 false，隐藏 x 轴的网格线
          },
          axisLabel: {
            formatter: function (value: any, index: any) {
              // 自定义刻度标签的显示内容
              // 您可以根据需要修改以下逻辑
              if (value > 10000000) return value / 1000000;
              return value;
            },
          },
          nameTextStyle: {
            fontSize: 20,
          },
        },
        yAxis: {
          name: option.yName,
          scale: true,
          nameLocation: "middle",
          nameGap: option.yNameGap || 30,
          min: 0,
          splitLine: {
            show: false, // 设置为 false，隐藏 x 轴的网格线
          },
          nameTextStyle: {
            fontSize: 20,
          },
        },
        visualMap: option.visualMap || null,
        series: [
          {
            type: "scatter",
            name: "Female",
            // prettier-ignore
            data: dataList.filter((item: any) => item[2] !== "target"),
            itemStyle: {
              color: function (params: any) {
                return coloc_map[params.value[2]];
              },
              borderColor: 'black', // 设置边框颜色为黑色
              borderWidth: 1,       // 设置边框宽度
            },
            symbol: function (params: any) {
              return "circle";
            },
            symbolSize: function (params: any) {
              return option.symbolSize || 12;
            },
          },
          {
            type: "scatter",
            data: dataList.filter((item: any) => item[2] === "target"),
            itemStyle: {
              color: coloc_map.target,
              borderColor: 'black', // 设置边框颜色为黑色
              borderWidth: 1,       // 设置边框宽度
            },
            symbol: function (params: any) {
              return "diamond";
            },
            symbolSize: function (params: any) {
              return 20;
            },
            markPoint: {
              silent: true,
              symbol: (params: any) => "",
              symbolSize: 0,
              animation: true,
              position: "top",
              // symbolRotate: 90,
              // symbolOffset: [14, 0],
              // z: 100,
              data: [
                {
                  name: "",
                  coord: dataList.filter(
                    (item: any) => item[2] === "target"
                  )[0], // 最大值点的坐标
                },
              ],
              itemStyle: { color: "rgb(150, 50, 184)" },
              label: {
                show: true,
                distance: 10,
                position: "top",
                formatter: function (params: any) {
                  return params.data.coord[3].snp;
                },
                fontSize: 16,
              },
            },
          },
          ...(option.series || []),
        ],
      };
      option.yMax && (curOption.yAxis.max = option.yMax);
      curOption && myChart.setOption(curOption);
      // 添加点击事件监听器
      clickCallback &&
        myChart.on("click", function (params: any) {
          clickCallback(params.data[3]?.gene_id);
        });
    }, [clickCallback, dataList, option, tooltip]);

    return (
      <div
        style={{
          width: option.width + "px",
          height: option.height + "px",
          ...option.style,
        }}
        ref={chartRef}
      ></div>
    );
  },
  (prevProps: any, nextProps: any) => {
    if (!_.isEqual(prevProps.dataList, nextProps.dataList)) return false;
    return true;
  }
);
