import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import * as echarts from "echarts";
import svglogo from 'src/svg_logo.jpg';
import { text } from "stream/consumers";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
interface Props {
  dataList: any[];
  option: {
    width?: number;
    height?: number;
    xName?: string;
    yName?: string;
    yMax?: number;
    xAxis?: any;
    yNameGap?: any;
    name?: any;
    style?: any;
    symbolSize?: any;
    visualMap?: any;
    series?: any;
  };
  // line?: any;
  slope?: any;
  Pearson: any;
  intercept?: any;
  clickCallback?: any;
}
///////////////////

const coloc_map: any = {
  target: "rgb(150, 50, 184)",
  single_chr: "rgb(175, 175, 175)",
  double_chr: "rgb(0, 123, 255)",
  r2_five: "#f50703",
  r2_four: "#f8a402",
  r2_thr: "#186400",
  r2_two: "#87ceeb",
  r2_one: "#0c028b",
};
function renderItem(params: any, api: any) {
  const group: any = {
    type: "group",
    children: [],
  };
  let coordDims = ["x", "y"];
  for (let baseDimIdx = 0; baseDimIdx < 2; baseDimIdx++) {
    let otherDimIdx = 1 - baseDimIdx;
    let encode = params.encode;
    let color = params.color;
    let baseValue = api.value(encode[coordDims[baseDimIdx]][0]);
    let param = [];
    param[baseDimIdx] = baseValue;
    param[otherDimIdx] = api.value(encode[coordDims[otherDimIdx]][1]);
    let highPoint = api.coord(param);
    param[otherDimIdx] = api.value(encode[coordDims[otherDimIdx]][2]);
    let lowPoint = api.coord(param);
    // var style = api.style({
    //   stroke: api.value(7) ? "grey" : "rgb(0, 123, 255)",
    //   // stroke: api.value(7) ? coloc_map[params.data]: "rgb(0, 123, 255)",
    //   fill: undefined,
    // });
    // 在这里修改standard error bar的颜色
    var style = api.style({
      stroke: api.value(7) ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.1)",
      width: 1, // 设置线条宽度
      fill: undefined,
    });
    group.children.push({
      type: "line",
      transition: ["shape"],
      shape: makeShape(
        baseDimIdx,
        highPoint[baseDimIdx],
        highPoint[otherDimIdx],
        lowPoint[baseDimIdx],
        lowPoint[otherDimIdx]
      ),
      style: style,
    });
  }
  function makeShape(
    baseDimIdx: any,
    base1: any,
    value1: any,
    base2: any,
    value2: any
  ) {
    let shape: any = {};
    shape[coordDims[baseDimIdx] + "1"] = base1;
    shape[coordDims[1 - baseDimIdx] + "1"] = value1;
    shape[coordDims[baseDimIdx] + "2"] = base2;
    shape[coordDims[1 - baseDimIdx] + "2"] = value2;
    return shape;
  }
  return group;
}
const dimensions: string[] = [
  "snp",
  "GWAS effect size",
  "eQTL effect size",
  "eQTL standard error min",
  "eQTL standard error max",
  "GWAS standard error min",
  "GWAS standard error max",
];
/////////////////
export default React.memo(
  function BetaScatterPlotComponent(props: Props) {
    const {
      dataList,
      Pearson,
      // line,
      slope,
      intercept,
      option = {
        width: 500,
        height: 100,
        xName: "",
        yName: "",
        xAxis: null,
        yMax: 30,
        yNameGap: 30,
        symbolSize: 8,
        name: "",
        style: {},
        visualMap: null,
        series: [],
      },

      clickCallback,
    } = props;
    const chartRef = useRef<any>(null);
    console.log("dataListgfdsgdf",dataList)
    console.log("slope",slope)
    console.log("intercept",intercept)
    useEffect(() => {
      let chartDom = chartRef.current;
      let myChart = echarts.init(chartDom, "", { renderer: "svg" });

      const data = dataList.map((item: any, index: number) => {

        let obj = item.slice(0, 3);
        // obj.push(item[2]); // eqtl
        // obj.push(item[1]); // gwas
        obj.push(item[2] - item[3]); // eqtl
        obj.push(item[2] + item[3]);
        obj.push(item[1] - item[4]); //gwas
        obj.push(item[1] + item[4]);
        // if (item[5] === "target"){
        //   obj.push("target");
        // } else {
        //   obj.push("");
        // }
        obj.push(item[5]);
        obj.push(item[6]);
        return obj;
      });
      let xMin = Math.min(...data.map((item) => item[3])) - 0.1;
      let xMax = Math.max(...data.map((item) => item[4])) + 0.1;
      console.log("xMin: ", xMin)
      console.log("xMax: ", xMax)
      console.log("data: ", data)
      let eqtlbetaMin = Math.min(...data.map((item) => item[2])) - 0.3;
      let eqtlbetaMax = Math.max(...data.map((item) => item[2])) + 0.3;
      let xData = [eqtlbetaMin, eqtlbetaMax].map((item) => {
        return [item, slope * item + intercept];
      }); // x轴的数据范围，可以根据需求调整
      console.log("xData: ", xData)
      const curOption: any = {
        title: { text: option.name, left: "center" },
        tooltip: {},
        toolbox: {
          right: 0,
          bottom: 0,
          itemSize:50,
          feature: {
              saveAsImage: {
                show: true,
                icon: `image://${svglogo}`,
                name: 'effectsizeplot',
              },
          },
        }, //图片下载功能
        xAxis: option.xAxis || {
          name: option.xName,
          scale: true,
          type: "value",
          nameLocation: "middle",
          nameGap: 20,
          // min: xMin,
          // max: xMax,
          axisLine: {
            onZero: false,
          },
          axisTick: {
            show: false,
          },          
          splitLine: {
            show: false, // 设置为 false，隐藏 x 轴的网格线
          },
          axisLabel: {
            formatter: function (value: any, index: any) {
              // 自定义刻度标签的显示内容
              // 您可以根据需要修改以下逻辑
              if (value > 10000000) return value / 1000000;
              return value;
            },
          },
          nameTextStyle: {
            fontSize: 20,
          },

        },
        yAxis: {
          name: option.yName,
          scale: true,
          nameLocation: "middle",
          nameGap: option.yNameGap || 30,
          type: "value",
        //   min: 0,
          splitLine: {
            show: false, // 设置为 false，隐藏 x 轴的网格线
          },
          axisLine: {
            onZero: false,
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontSize: 20,
            overflow: 'breakAll',
          },
        },
        visualMap: option.visualMap || null,
        series: [
          {
            type: "scatter",
            // name: "Female",
            // prettier-ignore
            data: data.filter((item: any) => item[7] !== "target"),
            // data: data.filter((item: any) => item[7] === "single_chr"),
            itemStyle: {
              color: function (params: any) {
                // return coloc_map[params.value[2]];
                return coloc_map[params.value[7]];
              },
              borderColor: 'black', // 设置边框颜色为黑色
              borderWidth: 1,       // 设置边框宽度
            },
            // itemStyle: {
            //   color: coloc_map.single_chr,
            // },
            symbol: function (params: any) {
              return "circle";
            },
            symbolSize: function (params: any) {
              return option.symbolSize || 12;
            },
            dimensions: dimensions,
            encode: {
              x: 2,
              y: 1,
              tooltip: [2, 1, 3, 4, 5, 6],
              itemName: 0,
            },
          },

          {
            type: "scatter",
            // data: dataList.filter((item: any) => item[4] === "target"),
            data: data.filter((item: any) => item[7] === "target"),
            dimensions: dimensions,
            itemStyle: {
              color: coloc_map.target,
              borderColor: 'black', // 设置边框颜色为黑色
              borderWidth: 1,       // 设置边框宽度
            },
            symbol: function (params: any) {
              return "diamond";
            },
            symbolSize: function (params: any) {
              return 20;
            },
            encode: {
              x: 2,
              y: 1,
              tooltip: [2, 1, 3, 4, 5, 6],
              itemName: 0,
            },
            
            markPoint: {
              silent: true,
              symbol: (params: any) => "",
              symbolSize: 0,
              animation: true,
              position: "top",
              // symbolRotate: 90,
              // symbolOffset: [14, 0],
              // z: 100,
              data: [
                {
                  name: "",
                  // coord: dataList.filter((item: any) => item[4] === "target")[0]
                  coord: data.filter((item: any) => item[7] === "target")[0]
                  // 最大值点的坐标
                },
              ],
              itemStyle: { color: "rgb(150, 50, 184)" },
              label: {
                show: true,
                distance: 10,
                position: "top",
                formatter: function (params: any) {
                  return params.data.coord[3].snp;
                },
                fontSize: 16,
              },
            },
          },
          {
            type: "custom",
            renderItem: renderItem,
            dimensions: dimensions,
            encode: {
              x: [2, 3, 4],
              y: [1, 5, 6],
              tooltip: [2, 1, 3, 4, 5, 6],
              itemName: 0,
              // data: data,
            },
            data: data.filter((item: any) => item[7]),
            // data: data.filter((item: any) => item[7] !== "4"),

            z: 100,
          },
          {
            name: "Dynamic Line",
            type: "line",
            symbol: "none",
            data: xData,
            lineStyle: {
              color: "lightgray",
              type: "dashed", // 设置线条样式为虚线
              width: 2, // 设置线条的粗细
            },
            z: 1,
          },
          ...(option.series || []),
        ],
        graphic: [
          {
            type: 'text',
            left: 450,
            top: 430,
            style: {
              text: `Pearson Correlation: ${Pearson.pearson_corr}`,
              // text: `Pearson Correlation: ${Pearson}`,
              fontsize: 20,
              fontWeight: 'normal',
              fill: 'black',
            }
          },
          {
            type: 'text',
            left: 450,
            top: 450,
            style: {
              text: `P-value: ${Pearson.pearson_pval}`,
              // text: `P-value: ${Pearson}`,
              fontsize: 20,
              fontWeight: 'normal',
              fill: 'black',
            }
          }
        ]
      };
      option.yMax && (curOption.yAxis.max = option.yMax);
      curOption && myChart.setOption(curOption);
      // 添加点击事件监听器
      clickCallback &&
        myChart.on("click", function (params: any) {
          clickCallback(params.data[3]?.gene_id);
        });
    // }, [clickCallback, dataList, option, tooltip]);
    }, [clickCallback, dataList, option]);

    return (
      <div
        style={{
          width: option.width + "px",
          height: option.height + "px",
          ...option.style,
        }}
        ref={chartRef}
      ></div>
    );
  },
  (prevProps: any, nextProps: any) => {
    if (!_.isEqual(prevProps.dataList, nextProps.dataList)) return false;
    return true;
  }
);
