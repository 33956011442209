import "./App.css";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createHashHistory } from "history";
import Router from "./Router";
// import Router from "./Router";

const history = createHashHistory({ window });

function App() {
  return (
    <HistoryRouter history={history as any}>
      <Router />
    </HistoryRouter>
  );
}

export default App;
