let genome_data = [
  { chr: 1, base_pairs: 248956422 },
  { chr: 2, base_pairs: 242193529 },
  { chr: 3, base_pairs: 198295559 },
  { chr: 4, base_pairs: 190214555 },
  { chr: 5, base_pairs: 181538259 },
  { chr: 6, base_pairs: 170805979 },
  { chr: 7, base_pairs: 159345973 },
  { chr: 8, base_pairs: 145138636 },
  { chr: 9, base_pairs: 138394717 },
  { chr: 10, base_pairs: 133797422 },
  { chr: 11, base_pairs: 135086622 },
  { chr: 12, base_pairs: 133275309 },
  { chr: 13, base_pairs: 114364328 },
  { chr: 14, base_pairs: 107043718 },
  { chr: 15, base_pairs: 101991189 },
  { chr: 16, base_pairs: 90338345 },
  { chr: 17, base_pairs: 83257441 },
  { chr: 18, base_pairs: 80373285 },
  { chr: 19, base_pairs: 58617616 },
  { chr: 20, base_pairs: 64444167 },
  { chr: 21, base_pairs: 46709983 },
  { chr: 22, base_pairs: 50818468 },
  { chr: 23, base_pairs: 156040895 },
  { chr: 24, base_pairs: 57227415 },
];

export let genome_data_merged: any = [];
let genome_end = 0;
genome_data.forEach(function (d, i) {
  genome_data_merged.push({});
  genome_data_merged[i].chr = d.chr;
  genome_data_merged[i].base_pairs = d.base_pairs;

  genome_data_merged[i].genome_start = genome_end;

  genome_end += d.base_pairs;
  genome_data_merged[i].genome_end = genome_end;

  genome_data_merged[i].tickpoint =
    genome_data_merged[i].genome_start + Math.round(d.base_pairs / 2);
});
