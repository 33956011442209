import axios, { AxiosError } from "axios";
import { Env } from "src/helpers/env";

export const BASE_URL = `${window.location.protocol}//${(Env as any).get(
  "base_url"
)}`;
const request = axios.create({
  baseURL: BASE_URL,
});
request.interceptors.request.use(async (config) => {
  const ssoToken = localStorage.getItem("token");
  if (ssoToken) {
    config.headers!.token = ssoToken;
  }
  return config;
});
// request.interceptors.response.use(undefined, (error: AxiosError<unknown>) => {
//   throw error;
// });

export default request;
