import styled, { css } from "styled-components";
import { Affix, Alert, Button, Form, Spin, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import Input from "src/components/common/Input";
import loginApis from "src/service/api/login";
import { HomeOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
interface LoginComponentProps {}
type FormDataType = {
  username: string;
  password: string;
};

const Login = styled.h2`
  position: fixed;
  top: 40px;
  left: 40px;
`;
const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const FormContainer = styled.div`
  width: 400px;
  background-color: #fff;
  padding: 28px;
  border-radius: 10px;
  margin: auto;
`;
const FormHeader = styled.h1`
  text-align: center;
  font-size: 32px;
  margin-bottom: 64px;
`;
const FormDiv = styled(Form)`
  width: 368px;
`;
const ForgetButtonFormItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const InputFormItem = styled(Form.Item)`
  .ant-form-item-explain {
    padding: 2px 0 8px 0;
  }
`;

function LoginComponent(props: LoginComponentProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState<boolean>(false);

  const loginHandle = useCallback(async () => {
    try {
      setIsloading(true);
      const values = await form.validateFields();
      const rep = await loginApis.login(values);
      if (rep.status === 200 && rep.data.code === 200) {
        message.success("login success!");
        localStorage.setItem("token", rep.data.data);
        localStorage.setItem("email", values.email);
        navigate("/home", { state: { login: true } });
      } else {
        message.error(rep.data.data || rep.data.msg);
      }
      setIsloading(false);
    } catch (e) {
      setIsloading(false);
      console.log(e);
    }
  }, [form, navigate]);

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <RightContainer>
        <Login>
          <Button
            type="link"
            size="large"
            icon={<HomeOutlined />}
            onClick={() => navigate("/home")}
          >
            <span>LocusCompare v2</span>
          </Button>
        </Login>
        <FormContainer>
          <FormHeader>Login</FormHeader>
          <FormDiv
            initialValues={{ username: "" }}
            labelCol={{
              span: 0,
            }}
            autoComplete="off"
            validateTrigger={false}
            form={form}
          >
            <InputFormItem
              name="email"
              rules={[
                { required: true },
                {
                  type: "email",
                  message: "",
                },
              ]}
            >
              <Input
                placeholder={"Email"}
                prefix={
                  <UserOutlined
                    css={`
                      color: #1890ff;
                    `}
                  />
                }
              />
            </InputFormItem>

            <InputFormItem name="password" rules={[{ required: true }]}>
              <Input
                type="password"
                placeholder={"Password"}
                prefix={
                  <LockOutlined
                    css={`
                      color: #1890ff;
                    `}
                  />
                }
              />
            </InputFormItem>

            <ForgetButtonFormItem>
              <Link to={"/register"} replace>
                Register
              </Link>
              <Link to={"/forgot-password"} replace>
                Forgot your password?
              </Link>
            </ForgetButtonFormItem>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
                onClick={loginHandle}
              >
                login
              </Button>
            </Form.Item>
          </FormDiv>
        </FormContainer>
      </RightContainer>
    </Spin>
  );
}

export default LoginComponent;
