import * as Antd from "antd";
import { InputRef } from "antd";
import { forwardRef } from "react";
import styled from "styled-components";

interface InputProps extends Antd.InputProps {}

const AntInput = styled(Antd.Input)`
  border-radius: 4px;
  .ant-input-prefix {
    margin-right: 8px;
  }
`;

const Input = forwardRef<InputRef, InputProps>((props, ref) => {
  return <AntInput ref={ref} size="large" {...props} />;
});

export default Input;
