import { getManhattanPlot } from "src/service/api-service";
import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { MANHATTAN_DATA } from "src/util/manhattanData";
import ScatterPlotComponentOrigion from "./ScatterPlotComponentOrigion";
import line_png from "src/line.png";
import { genome_data_merged } from "src/util/chromosome";
import _ from "lodash";
// import html2canvas from "html2canvas";

export default React.memo(function ManhattanPlotComponent(props: {
  trait: string;
  tissue: string;
  isOffline: boolean;
  recordId: any;
}) {
  const { trait, tissue, isOffline, recordId } = props;
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [dataInfo, setDataInfo] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        if (trait) {
          setIsloading(true);
          const data = await getManhattanPlot({
            trait,
            tissue,
            isOffline,
            recordId: trait,
          });
          setDataInfo(data);
          setIsloading(false);
        }
      } catch (error) {
        setIsloading(false);
      }
    })();
  }, [isOffline, tissue, trait]);

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <div
        id="man-plot"
        style={{ height: "450px", display: "flex", justifyContent: "center" }}
      >
        {dataInfo && (
          <ScatterPlotComponentOrigion
            dataList={
              isOffline
                ? dataInfo.map((item: any) => {
                    return [
                      item.position,
                      -Math.log10(item.pvalue),
                      item.chrom % 2 ? "single_chr" : "double_chr",
                      item,
                    ];
                  })
                : dataInfo.map((item: any) => {
                    return [
                      item.position,
                      item.pvalue,
                      item.chrom % 2 ? "single_chr" : "double_chr",
                      item,
                    ];
                  })
            }
            tooltip={{
              show: true,
              triggerOn: "click",
              position: "top",
              formatter: function (params: any) {
                return ` <div style="text-align:left"><span style="font-weight:600;font-size:16px">${
                  params.data[3].snp
                }</span><br />
            <span style="font-weight:600">-log10(pvalue)</span>: ${
              params.data[3].pvalue
            }<br />
            <span style="font-weight:600">position</span>: ${
              params.data[3].position -
              genome_data_merged[params.data[3].chrom - 1].genome_start
            }<br />
            <span style="font-weight:600">chrom</span>: ${
              params.data[3].chrom
            }<br /></div>`;
              },
            }}
            option={{
              width: 1000,
              height: 450,
              yName: "-log10(P)",
              yMax: (_.maxBy(dataInfo, "pvalue") as any)?.pvalue < 30 ? 30 : 0,
              xAxis: {
                name: "Genomic Position (number denotes chromosome)",
                nameLocation: "middle",
                nameGap: 30,
                show: true,
                min: 0,
                max: 3088269832,
                splitLine: {
                  show: false, // 设置为 false，隐藏 x 轴的网格线
                },
                axisTick: {
                  show: false, // 设置为 false，隐藏 x 轴的网格线
                },
                axisLabel: {
                  show: false, // 设置为 false，隐藏 x 轴的网格线
                },
                nameTextStyle: {
                  fontSize: 20,
                },
              },
              yNameGap: 40,
              name: "Manhattan Plot",
              style: {
                backgroundImage: `url(${line_png})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "96px 381px",
                backgroundSize: "789px",
              },
              symbolSize: 6,
              series: [
                {
                  name: "Dynamic Line",
                  type: "line",
                  symbol: "none",
                  data: [
                    [0, 7.301029995663981],
                    [3088269832, 7.301029995663981],
                  ],
                  z: -1,
                  lineStyle: {
                    color: "lightgray",
                    type: "dashed", // 设置线条样式为虚线
                    width: 2, // 设置线条的粗细
                  },
                },
              ],
            }}
          />
        )}
      </div>
    </Spin>
  );
});
