import * as _ from "lodash";
import { IGenes, ISelect } from "../report";

export const GET_TOOLS_REPORT = "callbackGetToolsReportData";
export const GET_MABHATTAN_REPORT = "callbackGetManhattanData";
export const GET_GENES_REPORT = "callbackGetGenesReportData";
export const GET_PLOTS_REPORT = "callbackGetPlotsReportData";

export type JsonpCallbackNameType =
  | typeof GET_TOOLS_REPORT
  | typeof GET_MABHATTAN_REPORT
  | typeof GET_GENES_REPORT
  | typeof GET_PLOTS_REPORT;

export const RANK_MAP: any = {
  coloc: "coloc",
  smr: "smr",
  fastenloc: "fastenloc",
  predixcan: "predixcan",
  ecaviar: "ecaviar",
  fusion: "fusion",
  intact: "intact_probability",
  rank: "rank_index",
  geo: "geo_ranking",
};
const win: any = window;
export const localtionDir = win.location.pathname.replace(
  "index.html",
  "data/"
);
// 获取jsonp数据
export function getJsonPData(
  callabckName: JsonpCallbackNameType,
  url_path: string
): any {
  return new Promise((resolve, reject) => {
    const win: any = window;
    win[callabckName] = function (data: any) {
      if (!data || data.length === 0) {
        console.warn("data is nul");
        resolve([]);
      }
      // data = data.filter((item: any) => item.pvalue !== 0)
      console.log(callabckName, url_path, data);
      resolve(data);
    };

    const scriptDom = document.createElement("script");
    if (win.location.protocol === "http:") {
      scriptDom.src = "http://localhost:3000/data/" + url_path;
    } else {
      // 修复文件夹拖动后加载jsonp数据文件路径错误问题
      const localtionName = localtionDir + url_path;
      scriptDom.src = "file://" + localtionName + "?callback=" + callabckName;
    }

    win.document.body.append(scriptDom);
  });
}

export function drawPlot(callback: any) {
  return new Promise((resolve: any, reject: any) => {
    callback();
    setTimeout(() => {
      resolve();
    }, 2000);
  });
}

export function parseTreeSelect(_arry: IGenes[]): ISelect[] {
  let arry: any = [];
  let hashObj: any = {};
  _arry.forEach((item: any) => {
    for (let toolName of [
      "coloc",
      "ecaviar",
      "fastenloc",
      "predixcan",
      "smr",
    ]) {
      if (item[toolName] > -1) {
        if (hashObj[toolName] !== undefined) {
          arry[hashObj[toolName]].children.push({
            title: item.gene_id,
            value: toolName + "_" + item.gene_id,
            key: toolName + "_" + item.gene_id,
          });
        } else {
          hashObj[toolName] = arry.length;
          arry.push({
            title: toolName,
            value: toolName,
            children: [
              {
                title: item.gene_id,
                value: toolName + "_" + item.gene_id,
                key: toolName + "_" + item.gene_id,
              },
            ],
          });
        }
      }
    }
  });
  arry.unshift({
    title: "all",
    value: "all",
    children: [],
  });
  return arry;
}
