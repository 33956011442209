import request from "../request";

interface IRegisterRequest {
  email: string;
  password: string;
  organisation: string;
  code: string;
}

async function register(data: IRegisterRequest) {
  return await request.post(`api/v1/users/register`, data);
}
async function getCode(email: string) {
  return await request.post(`api/v1/users/send_code`, { email });
}
async function login(data: Pick<IRegisterRequest, "email" | "password">) {
  return await request.post(`api/v1/users/login`, data);
}
async function forgot(
  data: Pick<IRegisterRequest, "email" | "code"> & { new_password: string }
) {
  return await request.post(`api/v1/users/login_forget`, data);
}

const api = { register, getCode, login, forgot };
export default api;
