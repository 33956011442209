import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import * as echarts from "echarts";
import svglogo from 'src/svg_logo.jpg';

import ecStat from "echarts-stat";
import { Pagination, Row, Spin } from "antd";
import { getTpmList } from "src/service/api/home";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 480px;
  width: 100%;
  margin: auto;
`;

export default React.memo(function GeneTpmComponent(props: {
  gene_id: string;
}) {
  const { gene_id } = props;
  const [isLoading, setIsloading] = useState<boolean>(false);
  const chartRef = useRef<any>(null);
  const chartInstance = useRef<any>(null); // 定义 chartInstance

  useEffect(() => {
    (async () => {
      try {
        setIsloading(true);
        const data = await getTpmList({
          page: 0,
          page_size: 50,
          gene_id: gene_id,
        });
        let chartDom = chartRef.current;
        let myChart = echarts.init(chartDom, "", { renderer: "svg" });
        echarts.registerTransform((ecStat as any).transform.regression);
        const option: any = {
          xAxis: {
            type: "category",
            data: data?.data?.data?.list?.map((item: any) => item.tissue),
            splitArea: {
              show: true,
            },
            axisLabel: {
              interval: 0,
              rotate: 45,
            },
          },
          grid: {
            height: "40%",
            top: "10%",
            left: "120px",
          },
          yAxis: {
            type: "value",
            name: "gene median tpm",
          },
          toolbox: {
            right: 0,
            bottom: 0,
            itemSize:50,
            feature: {
                saveAsImage: {
                  show: true,
                  icon: `image://${svglogo}`,
                  name: 'geneexpressionplot',
                },
            },
          }, //图片下载功能
          series: [
            {
              data: data?.data?.data?.list?.map((item: any) => item.value),
              type: "bar",
            },
          ],
        };
        option && myChart.setOption(option);
        setIsloading(false);
      } catch (error) {
        setIsloading(false);
      }
    })();
    return () => {};
  }, [gene_id]);

  const handleDownload = () => {
    if (chartInstance) {
      const base64Image = chartInstance.current.getDataURL({
        type: 'png'
      });
  
      // 创建一个虚拟链接（a 标签）
      const link = document.createElement('a');
      link.href = base64Image;
      link.download = 'gene-expression-plot.png';
  
      // 模拟点击虚拟链接，触发下载
      link.click();
    }
  };
  

  return (
    <div className="gwasPlot">
      <Spin tip="Loading..." spinning={isLoading}>
        <Row className="tissue-row-title juxtify-content-center font-weight-format" style={{fontSize:'150%'}}>
          GTEx Gene Expression
        </Row>

        <div
          style={{ width: "100%", height: "450px" }}
          id="gene_tmp_chart"
          ref={chartRef}
        />
        {/* <button onClick={handleDownload}>Download Image</button> */}
      </Spin>
    </div>
  );
});
