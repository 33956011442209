import React, { useEffect, useState, useCallback } from "react";
import * as _ from "lodash";
import { Collapse, Descriptions, Row, Spin, Table, Form, Input, Card } from "antd";
import { getColocConfig, getGenomicLoci } from "src/service/api/home";
import InputPage from "../InputPage";
import { DownloadOutlined} from "@ant-design/icons";
import {
  Button
} from "antd";
const { Panel } = Collapse;
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 18 },
};

export default React.memo(function GwasInputComponent(props: {
  task_id: number;
  summaryInfo: any;
  isLoadingForSummary: any;
}) {
  const { task_id, summaryInfo, isLoadingForSummary } = props;
  const [dataSource, setDataSource] = useState<any>([]);

  const [isLoadingForClumping, setIsloadingForClumping] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setIsloadingForClumping(true);
        const data = await getGenomicLoci(task_id);
        setDataSource(
          data?.data?.data?.map((item: any, index: any) => ({
            ...item,
            index: index + 1,
          }))
        );
        setIsloadingForClumping(false);
      } catch (error) {
        setIsloadingForClumping(false);
      }
    })();
  }, [task_id]);

  const columns = [
    {
      title: "lead SNP",
      dataIndex: "rs_id",
      key: "rs_id",
    },
    {
      title: "start",
      dataIndex: "start",
      key: "start",
    },
    {
      title: "end",
      dataIndex: "end",
      key: "end",
    },
    {
      title: "variant id",
      dataIndex: "var_id",
      key: "var_id",
    },
    {
      title: "the number of SNPs in this loci",
      dataIndex: "total_snp",
      key: "total_snp",
    },
  ];  
  
  const downloadLogfile = useCallback(() => {
    const downloadLink = document.createElement("a");
    downloadLink.href = summaryInfo?.log_file;
    downloadLink.download = "output.log";
    downloadLink.click();
  }, [summaryInfo?.log_file]);

  return (
    <div className="GwasContainer">
      
      {/* {summaryInfo?.submit_at && summaryInfo?.duration && (
        <Row className="row-margin font-weight-format">
          <Form
            {...formItemLayout}>
            <Form.Item 
              label="Submit time">
              <Input style={{ width: "100%" }} value={summaryInfo.submit_at} readOnly />
            </Form.Item>
            <Form.Item 
              label="Duration">
              <Input style={{ width: "100%" }} value={summaryInfo.duration} readOnly />
            </Form.Item>
          </Form>
        </Row>
      )}

      <Collapse>
        <Panel header="Input:" key="1">
            {summaryInfo?.eqtl && (
              <InputPage
                isLoadingForSummary={isLoadingForSummary}
                summaryInfo={summaryInfo}
              />
            )}
        </Panel>
      </Collapse> */}

  
      {/* <p>{summaryInfo?.eqtl}</p> */}

      {/* <ul>
        {Object.entries(summaryInfo || {}).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {String(value)}
          </li>
        ))}
      </ul> */}
      
      <div style={{ border: '1px solid #d3d3d3', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
        <h3>Input &nbsp;
          <Button icon={<DownloadOutlined />} onClick={downloadLogfile}>
            log file
          </Button>
        </h3>
        
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li style={{ marginBottom: '10px' }}>
            <strong>Trait Name:</strong> {summaryInfo?.trait}
          </li>
          <li style={{ marginBottom: '10px' }}>
            <strong>Sample Size:</strong> {summaryInfo?.sample_size}
          </li>
          <li style={{ marginBottom: '10px' }}>
            <strong>GWAS Threshold:</strong> {summaryInfo?.gwas_pval_threshold}
          </li>
          <li style={{ marginBottom: '10px' }}>
            <strong>Eqtl Tissue:</strong> {summaryInfo?.eqtl.join(', ')}
          </li>
          <li style={{ marginBottom: '10px' }}>
            <strong>Tools:</strong> {summaryInfo?.tools}
          </li>
          <li style={{ marginBottom: '10px' }}>
            <strong>Significant GWAS loci count:</strong> {summaryInfo?.significant_loci_count}
          </li>
          <li style={{ marginBottom: '10px' }}>
            <strong>Match snp count:</strong> {summaryInfo?.match_snp_count}
          </li>
          {/* 新增的参数显示 */}
          {Array.isArray(summaryInfo?.tool_param) && summaryInfo.tool_param.map((param, index) => {
            const paramNames = ['p1(coloc)', 'p2(coloc)', 'p12(coloc)', 'a0(fastenloc)', 'a0(fastenloc)']; // 参数名称
            return param !== null ? (
              <li style={{ marginBottom: '10px' }}>
                <strong>{`${paramNames[index]}:`}</strong> {param}
              </li>
            ) : null;
          })}
        </ul>
      </div>

    </div>

  );
});
