import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import * as echarts from "echarts";
import svglogo from 'src/svg_logo.jpg';

import { Spin } from "antd";
import { qqPlot } from "src/service/api/home";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 400px;
  margin: auto;
`;

function QQplot(props: {
  trait: string;
  tissue: string;
  isOffline: boolean;
  recordId: any;
}) {
  const { trait, tissue, isOffline, recordId } = props;
  const [isLoading, setIsloading] = useState<boolean>(false);
  const chartRef = useRef<any>(null);
  useEffect(() => {
    (async () => {
      try {
        if (trait) {
          setIsloading(true);
          const resData: any = await qqPlot(trait);
          let data = resData?.data?.data;
          let chartDom = chartRef.current;
          let myChart = echarts.init(chartDom, "", { renderer: "svg" });
          const option: any = {
            grid: { show: false },
            dataset: [
              {
                source: data.sort((a: any, b: any) => a[1] - b[1]),
              },
            ],
            title: {
              text: "QQ Plot",
              left: "center",
            },
            toolbox: {
              right: 0,
              bottom: 0,
              itemSize:50,
              feature: {
                  saveAsImage: {
                    show: true,
                    icon: `image://${svglogo}`,
                    name: 'qqplot',
                  },
              },
            }, //图片下载功能
            legend: {
              show: false,
              bottom: 5,
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
              },
            },
            xAxis: {
              name: "expected -log₁₀(p)",
              nameLocation: "middle",
              nameGap: 25,
              splitLine: {
                show: false,
              },
              axisLabel:{
                // fontSize: 28,
              },
              nameTextStyle: {
                fontSize: 20,
              },
            },
            yAxis: {
              name: "observed -log₁₀(p)",
              nameLocation: "middle",
              nameGap: 25,
              splitLine: {
                show: false,
              },
              nameTextStyle: {
                fontSize: 20,
              },
            },
            series: [
              {
                name: "scatter",
                type: "scatter",
                symbolSize: 5,
                z: 2,
              },
              {
                name: "Dynamic Line",
                type: "line",
                symbol: "none",
                data: [
                  [0, 0],
                  [7, 7],
                ],
                lineStyle: {
                  color: "lightgray",
                  type: "dashed", // 设置线条样式为虚线
                  width: 2, // 设置线条的粗细
                },
                z: 1,
              },
            ],
          };
          option && myChart.setOption(option);
          setIsloading(false);
        }
      } catch (error) {
        setIsloading(false);
      }
    })();
    return () => {};
  }, [isOffline, recordId, tissue, trait]);

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Container>
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
          }}
          id="qqplot"
          ref={chartRef}
        />
      </Container>
    </Spin>
  );
}

export default QQplot;
