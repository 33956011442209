import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Upload,
  UploadFile,
  message,
} from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Tooltip } from "antd";
import { DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { colocConfig, eqtlList, getColocConfig } from "src/service/api/home";
import LocalSingleFileUploader from "./LocalSingleFileUploader";
import { gwasFile, check_gwas } from "src/service/api/home";
import { useLocation, useNavigate } from "react-router-dom";
import useUploadProgress from "./component/useUploadProgress";
import { THRESHOLD_MAP_DETIAL } from "./ResultPage";
import { useAuthorize } from "..";
import Title from "antd/es/typography/Title";

import { ungzip } from "pako";
import { format } from "path";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const { Option } = Select;

interface EqtlItem {
  [key: string]: any;
}

const validFiles: string[] = ["tsv", "xlsx"];
const tools = ["coloc", "smr", "predixcan", "fusion", "fastenloc", "ecaviar"];
const limitedTools = ["coloc", "smr", "ecaviar"];
const GtexTissue = ['Small_Intestine_Terminal_Ileum',
'Stomach',
'Minor_Salivary_Gland',
'Pancreas',
'Artery_Coronary',
'Brain_Spinal_cord_cervical_c-1',
'Esophagus_Mucosa',
'Liver',
'Lung',
'Brain_Cortex',
'Whole_Blood',
'Brain_Cerebellar_Hemisphere',
'Heart_Left_Ventricle',
'Pituitary',
'Skin_Not_Sun_Exposed_Suprapubic',
'Spleen',
'Brain_Anterior_cingulate_cortex_BA24',
'Brain_Frontal_Cortex_BA9',
'Brain_Nucleus_accumbens_basal_ganglia',
'Brain_Caudate_basal_ganglia',
'Cells_Cultured_fibroblasts',
'Brain_Cerebellum',
'Colon_Transverse',
'Kidney_Cortex',
'Ovary',
'Nerve_Tibial',
'Testis',
'Brain_Hypothalamus',
'Vagina',
'Artery_Aorta',
'Brain_Substantia_nigra',
'Adrenal_Gland',
'Uterus',
'Brain_Hippocampus',
'Esophagus_Gastroesophageal_Junction',
'Cells_EBV-transformed_lymphocytes',
'Esophagus_Muscularis',
'Breast_Mammary_Tissue',
'Brain_Putamen_basal_ganglia',
'Muscle_Skeletal',
'Colon_Sigmoid',
'Thyroid',
'Skin_Sun_Exposed_Lower_leg',
'Adipose_Visceral_Omentum',
'Heart_Atrial_Appendage',
'Prostate',
'Artery_Tibial',
'Adipose_Subcutaneous',
'Brain_Amygdala'];

export default function InputPage(props: {
  summaryInfo?: any;
  isLoadingForSummary?: boolean;
}) {
  const { summaryInfo, isLoadingForSummary = false } = props;
  const userAuth = useAuthorize();
  const [form] = Form.useForm();
  const [fileForm] = Form.useForm();
  const [eqtls, setEqtls] = useState<any>();
  const { progress, reset, onUploadProgress } = useUploadProgress();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [selectedEqtls, setSelectedEqtls] = useState<string[]>([]);
  const [toolsOptions, setToolsOptions] = useState<string[]>([]);
  const [selectedTools, setSelectedTools] = useState<string[]>([]);

  useEffect(() => {
    if (!userAuth.isLogin || !localStorage.getItem("token")) {
      navigate("/login");
      return;
    }
  }, [navigate, userAuth.isLogin]);

  useEffect(() => {
    // 根据 selectedEqtls 动态更新 toolsOptions
    const containsCatalog = selectedEqtls.some(eqtl =>
      !GtexTissue.some(gtex => eqtl.includes(gtex))
    );
    const updatedToolsOptions = containsCatalog ? limitedTools : tools;
    setToolsOptions(updatedToolsOptions);

    const validSelectedTools = selectedTools.filter(tool => updatedToolsOptions.includes(tool));
    setSelectedTools(validSelectedTools);
    // 更新 form 中的 tools 字段值
    form.setFieldsValue({ tools: validSelectedTools });

  }, [selectedEqtls]);


  const isValidFileFormat = useCallback((name: string) => {
    const reg = new RegExp(`\\.(${validFiles.join("|")})$`);
    return true;
  }, []);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const eqtls: any = (await eqtlList())?.data?.data;
        setEqtls(eqtls);
      } catch (error) {}
    })();
  }, []);

  const handleFileRead = async(file: UploadFile): Promise<string> => {
    return new Promise((resolve, reject) => {
      const convertedFile = file as unknown as File; // convert UploadFile to File Type
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e?.target?.result as string;

        if (convertedFile.name.endsWith('.gz')) {
          const decompressedContent = ungzip(content);
          const decodedContent = new TextDecoder().decode(decompressedContent);
          resolve(decodedContent);
        } else {
          resolve(content as string);
        }
      };

      reader.onerror = (e) => {
        reject(e);
      };

      if (convertedFile.name.endsWith('.gz')) {
        reader.readAsArrayBuffer(convertedFile);
      } else {
        reader.readAsText(convertedFile);
      }
    });
  };

  const FormatCheck = async(mycontent:any, chrom:any, snp:any) => {
    const validateContent = (content:any) => {
      console.log("content: ", content)
      const lines = content.split("\n")
      console.log("lines.length: ", lines.length)
      if (lines.length <= 11) {
        return 1; // 行数不足10行，直接返回0, 第一行标题
      }
      const randomLines = getRandomLines(lines, 100); // 随机选择10行数据
      const regexNum = /^\d+$/; // 数字正则表达式
      const regexRs = /^rs/; // 以"rs"开头的正则表达式

      let ColumnName = lines[0].split('\t');
      if (!ColumnName.includes(chrom) || !ColumnName.includes(snp)) {
        return 2;
      }
      let ChrColmnIndex = ColumnName.indexOf(chrom);
      let SnpColmnIndex = ColumnName.indexOf(snp);
      for (let line of randomLines) {
        const columns = line.split('\t');

          const ChrColumn = columns[ChrColmnIndex];
          const SnpColumn = columns[SnpColmnIndex];

          if (!regexNum.test(ChrColumn)) {
            return 3;
          }

          if (!regexRs.test(SnpColumn)){
            return 4;
          }
      }

      return 0;
    };

    const getRandomLines = (lines:any, count:any) => {
      const shuffled = lines.slice(1).sort(() => 0.5 - Math.random()); // 去掉第一行标题并随机排序
      return shuffled.slice(0, count); // 选择前count行数据
    };

    const result = validateContent(mycontent);

    return result;
  };


  const submitForm = useCallback(async () => {
    // console.log("res:", fileList[0])

    try {
      setIsloading(true);
      const values = await form.validateFields();
      const res_all = await gwasFile({
        file: fileList[0],
        onUploadProgress
      });
      const res = res_all.gwas_raw_id

      await fileForm.validateFields();
      console.log("values:", values);
      const format_check_result = await check_gwas({
        s3_key : res_all.upload_to, 
        chrom : values.chrom, 
        snp : values.snp,
        ref: values.other_allele,
        alt: values.effect_allele,
      });

      if (format_check_result.checkformat !== 0) {
        setIsloading(false);
        message.error(format_check_result.extra_info);
        return;
      }
      // if (fileList[0].name.endsWith('.csv') || fileList[0].name.endsWith('.tsv') || fileList[0].name.endsWith('.gz')) {
      //   const content = await handleFileRead(fileList[0])
      //   const format_check_result = await FormatCheck(content,values.chrom,values.snp);
      //   if(format_check_result === 1){
      //     setIsloading(false);
      //     message.error("Input file has too few lines (must more than 11 lines)");
      //     return;
      //   }
      //   if(format_check_result === 2){
      //     setIsloading(false);
      //     message.error("Column names do not match!");
      //     return;
      //   }
      //   if(format_check_result === 3){
      //     setIsloading(false);
      //     message.error("Chromosome Column must contain only numbers!");
      //     return;
      //   }
      //   if(format_check_result === 4){
      //     setIsloading(false);
      //     message.error("SNP Column must start with \"rs\"!");
      //     return;
      //   }
      // }
      // else{
      //   setIsloading(false);
      //   message.error("Please upload csv/tsv or gz file");
      //   return;
      // }



      const gwas_param = {
        trait: values.trait,
        type: values.type,
        pval_threshold: parseFloat(values.pvalue_threshold),
        sample_size: parseInt(values.sample_size),
        rs_id: values.snp,
        chrom: values.chrom,
        position: values.position,
        beta: values.beta,
        effect_allele: values.effect_allele,
        other_allele: values.other_allele,
        p_value: values.p_value,
        standard_error: values.standard_error,
      };
      const tool_params = {
        a0: parseFloat(values.a0),
        a1: parseFloat(values.a1),
        p1: parseFloat(values.p1),
        p2: parseFloat(values.p2),
        p12: parseFloat(values.p12),
      };

      await colocConfig({
        gwas_params: JSON.stringify(gwas_param),
        tool_params: JSON.stringify(tool_params),
        gwas_raw_id: res,
        tissues: values.eqtls,
        tools: values.tools,
      });

      message.success("Submit Success");
      setIsloading(false);
      navigate(`/home/tasks`, {
        state: { menuKey: "8" },
      });
    } catch (e) {
      console.log(e);
      setIsloading(false);
      message.error("Submission Failed");
    }
  }, [fileForm, fileList, form, navigate, onUploadProgress]);

  const fileUploadProps = useMemo(
    () => ({
      beforeUpload(file: UploadFile) {
        // const isLtFiveHM = (file?.size || 0) / 1024 / 1024 < 500;
        const isLtFiveHM = (file?.size || 0) / 1024 / 1024 < 2048;
        if (!isLtFiveHM) {
          message.error("Maximun allow file size in 500MB!");
          // message.error("Maximun allow file size in 9999999MB!");
          return;
        }
        setFileList([file]);
        return;
      },
      onRemove() {
        setFileList([]);
      },
      // this overrides fileList state of Upload component
      fileList,
    }),
    [fileList]
  );
  const resetFormHandler = useCallback(async () => {
    form.resetFields();
    fileForm.resetFields();
    reset();
  }, [fileForm, form, reset]);

  const initialValues = useMemo(() => {
    if (summaryInfo?.eqtl) {
      return {
        ...summaryInfo,
        // pvalue_threshold: summaryInfo.gwas_pval_threshold || 0,
        pvalue_threshold: summaryInfo.gwas_pval_threshold || 5e-8,
        standard_error: summaryInfo.se,
        p1: summaryInfo.tool_param[0],
        p2: summaryInfo.tool_param[1],
        p12: summaryInfo.tool_param[2],
        a0: summaryInfo.tool_param[3],
        a1: summaryInfo.tool_param[4],
        eqtls: [...summaryInfo.eqtl],
        // tools,
        tools,
      };
    } else {
      return {
        tools,
        // pvalue_threshold: 0.00000005,
      };
    }
  }, [summaryInfo]);
  const readOnly = summaryInfo?.eqtl;
  return (
    <div className="input_container">
      <Spin tip="Loading..." spinning={isLoading || isLoadingForSummary}>
        <div className="ResultItem" style={{ margin: 0 }}>
          {summaryInfo?.eqtl ? (
            <Row className="font-weight-format">Configurations</Row>
          ) : (
            <>
              <Title level={3} style={{ textAlign: 'center' }}>Configurations</Title>
              <Divider />
            </>
          )}
          {!summaryInfo?.eqtl && (
            <Form
              form={fileForm}
              name="validate_other_1"
              {...formItemLayout}
              style={{ maxWidth: 600, margin: "auto", marginTop: 20 }}
            > 
              
              <Form.Item
                {...formItemLayout}
                name="file"
                label={<span style={{ whiteSpace: 'pre-line' }}>{'\n'}GWAS file{'\n'}(GRCh38)</span>}
                rules={[{ required: true }]}
              >
                
                <LocalSingleFileUploader
                  // accept={validFiles.map((suffix) => `.${suffix}`).join()}
                  text="Click or drag file to this area to upload"
                  description="Maximun allow file size in 500MB
                   (Please refer to tutorial for input file format)"
                  // description="Maximun allow file size in 9999999MB
                  //  (Please refer to tutorial for input file format)"
                  progress={progress || undefined}
                  isValidFileFormat={isValidFileFormat}
                  {...fileUploadProps}
                />
              </Form.Item>
            </Form>
          )}
          <Form
            form={form}
            name="validate_other"
            {...formItemLayout}
            initialValues={initialValues}
            style={{
              maxWidth: 600,
              margin: "auto",
              marginTop: 20,
              position: "relative",
            }}
          >
            {summaryInfo?.log_file && (
              <>
                <Form.Item {...formItemLayout} name="log_file" label="log file">
                  <Input
                    placeholder="no log file"
                    disabled={false}
                    readOnly={readOnly}
                  />
                </Form.Item>
                <Button
                  className="ButtonDownload"
                  icon={<DownloadOutlined />}
                  disabled={summaryInfo.log_file.indexOf("http") === -1}
                  type="primary"
                  shape="circle"
                  target="_blank"
                  href={initialValues.log_file}
                ></Button>
              </>
            )}

            <div style={{ 
              display: "flex",
              justifyContent: "center",
              maxWidth: 600,
              margin: "auto",
              marginBottom: 20,
              
              position: "relative",
            }}>
                <a href="https://drive.google.com/file/d/1ATRVZ2wkzt7D9VdGbPR3HLCKUnBaRFcX/view?usp=share_link" target="_blank" rel="noopener noreferrer">
                Download example file
                </a>
            </div>

            <Form.Item
              name="type"
              label="Type"
              hasFeedback
              rules={[{ required: true, message: "Please select type!" }]}
            >
              <Select placeholder="Please select a type" disabled={readOnly}>
                <Option value="cc">Case-Control</Option>
                <Option value="quant">Quantitative</Option>
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="trait"
              label="Trait name"
              rules={[{ required: true, message: "Please input trait name" }]}
            >
              <Input
                readOnly={readOnly}
                placeholder="Please input trait name"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="sample_size"
              label="Sample size"
              rules={[{ required: true, message: "Please input sample size" }]}
            >
              <InputNumber
                placeholder="Please input sample size"
                controls={false}
                style={{ width: "100%" }}
                readOnly={readOnly}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="pvalue_threshold"
              // label="P-value threshold"
              label={<span style={{ whiteSpace: 'pre-line' }}>P-value threshold{'\n'}(default: 5.0e-8)</span>}
              initialValue={5e-8}
              
              rules={[{ required: true, message: "Please set GWAS SNPs pvalue threshold: default 5e-8" }]}
            >
              <InputNumber
                placeholder="Please set GWAS pvalue threshold: default 5.0e-8"
                controls={false}
                style={{ width: "100%" }}
                readOnly={readOnly}
                // formatter = {
                //   v=>{
                //     if (v === undefined) {
                //       return ''
                //     }
                //     var vv = v.toString()
                //     var vvv:number = parseFloat(vv);
                //     return vvv.toExponential();
                //   }
                // }
                // parser = {v=>{
                //   if (v === undefined) {
                //     return 0.0
                //   }
                //   var vv:number = parseFloat(v);
                //   return vv;}}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="snp"
              label="SNP"
              rules={[{ required: true, message: "Please input SNP column name" }]}
            >
              <Input readOnly={readOnly} placeholder="Please input SNP column name" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="chrom"
              label="Chromosome"
              rules={[{ required: true, message: "Please input chrom column name" }]}
            >
              <Input readOnly={readOnly} placeholder="Please input chrom column name" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="position"
              label="Position"
              rules={[{ required: true, message: "Please input position column name" }]}
            >
              <Input readOnly={readOnly} placeholder="Please input position column name" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="effect_allele"
              label="Effect allele"
              rules={[
                { required: true, message: "Please input effect_allele column name" },
              ]}
            >
              <Input
                readOnly={readOnly}
                placeholder="Please input effect_allele column name"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="other_allele"
              label="Other allele"
              rules={[{ required: true, message: "Please input other_allele column name" }]}
            >
              <Input
                readOnly={readOnly}
                placeholder="Please input other_allele column name"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="p_value"
              label="P-value"
              rules={[{ required: true, message: "Please input pvalue column name" }]}
            >
              <Input readOnly={readOnly} placeholder="Please input pvalue column name" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="beta"
              label="beta"
              rules={[{ required: true, message: "Please input beta column name" }]}
            >
              <Input readOnly={readOnly} placeholder="Please input beta column name" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="standard_error"
              label="Standard error"
              rules={[{ required: true, message: "Please input se column name" }]}
            >
              <Input readOnly={readOnly} placeholder="Please input se column name" />
            </Form.Item>

            <Form.Item
              name="eqtls"
              label="eQTL"
              rules={[
                {
                  required: true,
                  message: "Please select eQTL!",
                  type: "array",
                },
                {
                  max: 10,
                  message: "You can only select up to 10 tissues.",
                  type: "array",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select eQTL"
                disabled={readOnly}
                onChange={(value) => setSelectedEqtls(value)}
              >
                {eqtls?.map((item: any) => (
                  <Fragment key={Object.values(item)[0] as any}>
                    <Option value={Object.keys(item)[0]}>
                      {Object.keys(item)[0]}
                    </Option>
                  </Fragment>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="tools"
              // label="Tools"
              label={
                <span>
                  Tools{" "}
                  <Tooltip title="If selecting eQTL from eQTL-Catalogue, only COLOC, eCAVIAR, SMR can be chosen as there is a lack of corresponding model support for the rest three tools.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please select tools!",
                  type: "array",
                },
                {
                  min: 2,
                  message: "At least select 2 tools.",
                  type: "array",
                },
              ]}
            >
              {/* <Select
                // disabled
                mode="multiple"
                placeholder="Please select tools"
              >
                {Object.entries(THRESHOLD_MAP_DETIAL).map((item: any) => (
                  <Option value={item[0]}>{item[1].label}</Option>
                ))}
              </Select> */}

              <Select
                mode="multiple"
                placeholder="Please select tools"
                disabled={readOnly}
                value={selectedTools}
                onChange={(value) => setSelectedTools(value)}
              >
                {/* {Object.entries(THRESHOLD_MAP_DETIAL).map((item: any) => (
                  <Option value={item[0]}>{item[1].label}</Option>
                ))} */}
                {toolsOptions.map((tool) => (
                  <Option key={tool} value={tool}>
                    {tool}
                  </Option>
                ))}
              </Select>



            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="p1"
              label="p1(coloc)"
              rules={[{ required: false }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Please set p1: default 1.0e-4"
                controls={false}
                readOnly={readOnly}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="p2"
              label="p2(coloc)"
              rules={[{ required: false }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Please set p2: default 1.0e-4"
                controls={false}
                readOnly={readOnly}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="p12"
              label="p12(coloc)"
              rules={[{ required: false }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Please set p12: default 1.0e-5"
                controls={false}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="a0"
              label="a0(fastenloc)"
              rules={[{ required: false }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Please set a0: default 1.0e-4"
                controls={false}
                readOnly={readOnly}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="a1"
              label="a1(fastenloc)"
              rules={[{ required: false }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Please set a1: default 1.0e-4"
                controls={false}
                readOnly={readOnly}
              />
            </Form.Item>

            {/* {summaryInfo?.eqtl && (
              <>
                <Form.Item
                  {...formItemLayout}
                  name="submit_at"
                  label="Submit time"
                  rules={[{ required: false }]}
                >
                  <Input style={{ width: "100%" }} readOnly={readOnly} />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="duration"
                  label="Duration"
                  rules={[{ required: false }]}
                >
                  <Input style={{ width: "100%" }} readOnly={readOnly} />
                </Form.Item>
              </>
            )} */}

            {!summaryInfo?.eqtl && (
              <Form.Item wrapperCol={{ span: 20, offset: 6 }}>
                <Button
                  style={{ width: "140px" }}
                  type="primary"
                  htmlType="submit"
                  onClick={submitForm}
                >
                  Check&Submit
                </Button>
                <Button
                  style={{ marginLeft: "20px", width: "140px" }}
                  onClick={resetFormHandler}
                >
                  Reset
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </Spin>
    </div>
  );
}