import React, { useEffect, useRef, useState } from "react";
import { IGenes } from "../../../../report";
import * as _ from "lodash";
import { Row, Spin } from "antd";
import line_png from "src/line.png";
import ScatterPlotComponent from "./ScatterPlotComponent";
import { genome_data_merged } from "src/util/chromosome";
interface Props {
  plotData: IGenes[];
  geneValue: any;
  setAndGeneToolValue: (geng_id: string) => void;
  isOffline: boolean;
  isLoadingForBarChart: boolean;
}
export default React.memo(function BarChartComponent(props: Props) {
  const {
    plotData,
    geneValue,
    setAndGeneToolValue,
    isOffline,
    isLoadingForBarChart,
  } = props;
  // console.log("plotData", plotData);
  // console.log("geneValue", geneValue);

  let yname = "tool_result";

  if (["predixcan", "smr", "fusion"].includes(geneValue)) {
    yname = "-log10(p_value)";
  };
  if (["coloc"].includes(geneValue)) {
    yname = "H4";
  };
  if (["fastenloc"].includes(geneValue)) {
    // yname = "LCP";
    yname = "GLCP";
  };
  if (["ecaviar"].includes(geneValue)) {
    yname = "CLPP";
  };

  const [chartInstance, setChartInstance] = useState<any>(null);

  const handleDownload = () => {
    if (chartInstance) {
      chartInstance.saveAsImage({
        name: 'colocalization-plot',
        type: 'png'
      });
    }
  };

  return (
    <Spin
      style={{ width: "100%" }}
      tip="Loading..."
      spinning={isLoadingForBarChart}
    >
      {plotData && (
        <ScatterPlotComponent
          dataList={plotData.map((item: any) => {
            return [
              genome_data_merged[item.chrom - 1].genome_start +
                (isOffline ? item.position : item.tss),
              isOffline ? item[geneValue] : (["predixcan", "smr", "fusion"].includes(geneValue) ? -Math.log10(item.tool_result) : item.tool_result),
              "double_chr",
              {
                gene_id: item.gene_id,
                gene_name: item.gene_name,
                chrom: item.chrom,
                tss: isOffline ? item.position : item.tss,
                tool_result: isOffline ? item[geneValue] : (["predixcan", "smr", "fusion"].includes(geneValue) ? -Math.log10(item.tool_result) : item.tool_result),
              },
            ];
          })}
          option={{
            width: 1000,
            height: 450,
            yName: yname,
            xAxis: {
              name: "Genomic Position (number denotes chromosome)",
              nameLocation: "middle",
              nameGap: 30,
              show: true,
              min: 0,
              max: 3088269832,
              splitLine: {
                show: false, // 设置为 false，隐藏 x 轴的网格线
              },
              axisTick: {
                show: false, // 设置为 false，隐藏 x 轴的网格线
              },
              axisLabel: {
                show: false, // 设置为 false，隐藏 x 轴的网格线
              },
              nameTextStyle: {
                fontSize: 20,
              },
            },
            yNameGap: 40,
            name: "Colocalization Plot",
            style: {
              backgroundImage: `url(${line_png})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "96px 381px",
              backgroundSize: "789px",
            },
          }}
          tooltip={{
            show: true,
            triggerOn: "click",
            position: "top",
            formatter: function (params: any) {
              return ` <span style="font-weight:600;font-size:16px">${params.data[3].gene_name}</span><br />
          <span style="font-weight:600">tool</span>: ${params.data[3].tool_result}<br />
          <span style="font-weight:600">tss</span>: ${params.data[3].tss}<br />
          <span style="font-weight:600">chrom</span>: ${params.data[3].chrom}<br />`;
            },
          }}
          clickCallback={setAndGeneToolValue}
        />
      )}
    </Spin>
  );
});
