import { ContainerOutlined } from "@ant-design/icons";
import { Progress, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { useCallback, useState } from "react";
import { css } from "styled-components";

export type FileUploaderProps = {
  accept?: string;
  progress?: number;
  text?: string;
  description?: string;
  customRequest?: (params: any) => any;
  isValidFileFormat?: (fileName: string) => boolean;
};

const useStyles = (props: FileUploaderProps) => ({
  root: css`
    .ant-upload {
      background-color: #fff;
    }
  `,
  icon: css`
    color: #1890ff;
    margin-bottom: 8px;
  `,
  text: css`
    color: #1890ff;
    margin-bottom: 4px;
  `,
  description: css`
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  `,
  progress: css`
    padding: 4px 0 0 22px;
    line-height: 0;
    .ant-progress-bg {
      height: 2px !important;
    }
  `,
});

function LocalSingleFileUploader(props: FileUploaderProps) {
  const {
    progress,
    text,
    description,
    customRequest,
    isValidFileFormat,
    ...restProps
  } = props;
  const styles = useStyles(props);

  const [fileList, setFileList] = useState<RcFile[]>([]);

  const handleBeforeUpload = useCallback(
    (file: RcFile) => {
      if (isValidFileFormat && !isValidFileFormat(file.name)) {
        return false;
      }
      setFileList([file]);
      return true;
    },
    [isValidFileFormat]
  );
  const handleRemove = useCallback(() => {
    setFileList([]);
  }, []);

  return (
    <div css={styles.root}>
      <Upload.Dragger
        fileList={fileList}
        beforeUpload={handleBeforeUpload}
        customRequest={customRequest || (() => {})}
        onRemove={handleRemove}
        {...restProps}
      >
        <div css={styles.icon}>
          <ContainerOutlined style={{ fontSize: 32 }} />
        </div>
        <div css={styles.text}>{text}</div>
        <div css={styles.description}>{description}</div>
      </Upload.Dragger>
      {progress != null && (
        <Progress
          css={styles.progress}
          strokeColor="#1890ff"
          percent={progress}
          showInfo={false}
        />
      )}
    </div>
  );
}

export default LocalSingleFileUploader;
