import { useInterval } from 'ahooks';
import { Button } from 'antd';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { css } from 'styled-components';

export type CountDownButtonProps = {
  children: (isCountDown: boolean, countDownNumber: number) => ReactNode;
  number: number;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => Promise<unknown> | void;
};

const useStyles = (props: CountDownButtonProps) =>
  useMemo(
    () => ({
      root: css``,
    }),
    []
  );

function CountDownButton(props: CountDownButtonProps) {
  const { children, number, loading, disabled, onClick } = props;
  const styles = useStyles(props);
  const [countDown, setCountDown] = useState<number>(0);
  const isCountDown = countDown > 0;

  useInterval(
    () => {
      setCountDown((num) => num! - 1);
    },
    isCountDown ? 1000 : undefined
  );

  const handleClick = useCallback(async () => {
    try {
      await onClick?.();
      setCountDown(number);
    } catch {}
  }, [number, onClick]);

  return (
    <Button size="large" loading={loading} disabled={!!countDown || disabled} onClick={handleClick}>
      {children(isCountDown, countDown)}
    </Button>
  );
}

export default CountDownButton;
