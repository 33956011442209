import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import MainFrame from "./MainFrame";
import LoginComponent from "./container/login/LoginComponent";
import RegisterComponent from "./container/login/RegisterComponent";
import ResultsPage from "./container/home/analysis/ResultPage";
import ForgotPasswordComponent from "./container/login/ForgotPasswordComponent";
import HomePage from "./container/home";
import TutorialContent from "./container/home/tutorial";
import MainContent from "./container/home/MainContent";
import InputPage from "./container/home/analysis/InputPage";
import ProfileContent from "./container/home/profile";
import TasksContent from "./container/home/tasks";
import ReferenceContent from "./container/home/reference";
type Redirection = { path: string; to: string };

const redirections: Redirection[] = [
  // {
  //   path: '/',
  //   to: '/datasets',
  // },
];
const routes: RouteObject[] = [
  ...redirections.map(({ path, to }) => ({
    path,
    element: <Navigate replace to={to} />,
  })),
  {
    path: "/",
    element: <HomePage />,
    children: [
      {
        path: "",
        element: <MainContent />,
      },
    ],
  },
  {
    path: "/",
    element: <MainFrame />,
    children: [
      {
        path: "login",
        element: <LoginComponent />,
      },
      {
        path: "register",
        element: <RegisterComponent />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordComponent />,
      },
      {
        path: "offline",
        element: <ResultsPage isOffline={true} />,
      },
    ],
  },
  {
    path: "/",
    element: <HomePage />,
    children: [
      {
        path: "home",
        element: <MainContent />,
      },
      {
        path: "home/tutorial",
        element: <TutorialContent />,
      },
      {
        path: "home/analysis/results",
        element: <ResultsPage isOffline={false} />,
      },
      {
        path: "home/analysis",
        element: <InputPage />,
      },
      {
        path: "home/reference",
        element: <ReferenceContent />,
      },
      {
        path: "/home/tasks",
        element: <TasksContent />,
      },
      {
        path: "/home/profile",
        element: <ProfileContent />,
      },
    ],
  },
];

function Router() {
  return useRoutes(routes);
}

export default Router;
