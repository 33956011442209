import { EChartsOption } from "echarts";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Echarts from "src/components/common/Echarts";
import { heatmapHover } from "src/service/api/home";
import { THRESHOLD_MAP_DETIAL, tool_map } from "../ResultPage";
import { Spin } from "antd";
import { parseValue } from "src/util/common";
import svglogo from 'src/svg_logo.jpg';

async function getHoverTips(
  params: any,
  query: any,
  seletTools: any,
  ticket: any,
  callback: any
) {
  let res = await heatmapHover(query);
  // console.log("12345:", seletTools)
  const text = ` <span style="font-weight:600;font-size:16px">Info</span><br />
          <span style="font-weight:600">Tissue</span>: ${
            params.data[3].tissue
          }<br />
          <span style="font-weight:600">Gene ID</span>: ${
            params.data[3].gene
          }<br />
          <span style="font-weight:600">Gene Name</span>: ${
            params.data[3].gene_name
          }<br />
          ${Object.entries(THRESHOLD_MAP_DETIAL)
            .filter((item: any) => seletTools.includes(item[0])) // 只保留在 seletTools 中的工具
            .map(
              (item: any) =>
                `${params.marker}${item[1].label}(${
                  item[1].pLabel
                }): ${parseValue(
                  res?.data?.data?.[0]?.[
                    item[0] === "fastenloc" ? "enloc" : item[0]
                  ]
                )}<br />`
            )
            .reduce((a, b) => a + b, "")}
    ${params.marker}INTACT: ${parseValue(res?.data?.data?.[0]['intact'])}<br />

  `;
  callback(ticket, text);
  //      ${params.marker}mean rank (arithmatic): ${parseValue(
  //   res?.data?.data?.[0]['mrank']
  // )}<br />
  //   ${params.marker}mean rank (geo matric): ${parseValue(
  //     res?.data?.data?.[0]['geo']
  // )}<br />
}

export const Container = styled.div<{ len: number }>`
  display: flex;
  flex-direction: column;
  height: 500px;
  /* width: 100%; */
  width: ${(props) => props.len * 50 + 120}px;
  max-width: 100%;
  margin: auto;
`;
export const ColocDiv = styled.div<{ colorValue: string }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.colorValue};
  border: 1px solid;
  vertical-align: middle;
`;
export const Chart = styled(Echarts)`
  flex: 1;
`;
function HeatChart(props: any) {
  const { dataSource, seletTools, columns, taskId, page, isOffline, isLoadingForHeatPlot } =
    props;
  const chartRef = useRef<any>(null);
  const [dataList, setDataList] = useState<any>();
  const y = dataSource
    ?.map((item: any, index: number) => item.gene_name)
    ?.filter(
      (item: any, index: number) =>
        !isOffline ||
        (index >= page.page_size * (page.page - 1) &&
          index < page.page_size * page.page)
    );
  const x = useMemo(
    () =>
      columns
        .map((item: any) => item.title)
        .filter((_: any, index: any) => index > 0),
    [columns]
  );
  useEffect(() => {
    let datas: any = [];
    const dataSourceCopy = dataSource?.filter(
      (item: any, index: number) =>
        !isOffline ||
        (index >= page.page_size * (page.page - 1) &&
          index < page.page_size * page.page)
    );
    // console.log("****dataSourceCopy", dataSourceCopy)
    // console.log("****dataSource", dataSource)

    dataSourceCopy.forEach((item: any, index: number) => {
      datas.push(
        ...x?.map((tissueName: any, innerIndex: number) => {
          return [
            innerIndex,
            index,
            isOffline
              ? Object.keys(tool_map)?.filter((toolName: any) => {
                  return dataSourceCopy[index][x[innerIndex]]?.[toolName] > -1;
                }).length
              : dataSourceCopy[index][x[innerIndex]],
            isOffline
              ? {
                  ...dataSourceCopy[index][x[innerIndex]],
                  gene: dataSourceCopy[index].gene,
                  tissue: x[innerIndex],
                }
              : {
                  gene: dataSourceCopy[index].gene,
                  tissue: x[innerIndex],
                  intact: dataSourceCopy[index].intact,
                  rank: dataSourceCopy[index].rank,
                  geo: dataSourceCopy[index].geo,
                  gene_name: dataSourceCopy[index].gene_name,
                },
          ];
        })
      );
    });
    setDataList(datas);
    console.log("****datas", datas)
  }, [dataSource, isOffline, page.page, page.page_size, x]);
  const option = useMemo<EChartsOption>(
    () => ({
      toolbox: {
        right: 0,
        bottom: 0,
        itemSize:50,
        feature: {
            saveAsImage: {
              show: true,
              icon: `image://${svglogo}`,
              name: 'heatmapplot',
            },
        },
      }, //图片下载功能
      tooltip: {
        position: "top",
        triggerOn: "click",
        formatter: ((params: any, ticket: any, callback: any) => {
          if (isOffline) {
            return ` <span style="font-weight:600;font-size:16px">Info</span><br />
          <span style="font-weight:600">Tissue</span>: ${
            params.data[3].tissue
          }<br />
          <span style="font-weight:600">Gene ID</span>: ${
            params.data[3].gene
          }<br />
          <span style="font-weight:600">Gene Name</span>: ${
            params.data[3].gene_name
          }<br />
          ${Object.entries(THRESHOLD_MAP_DETIAL)
            .map(
              (item: any) =>
                `${params.marker}${item[1].label}(${
                  item[1].pLabel
                }): ${parseValue(params.data[3]?.[item[0]])}<br />`
            )
            .reduce((a, b) => a + b, "")}
    ${params.marker}INTACT: ${parseValue(
              params.data[3].intact_probability
            )}<br />
    ${params.marker}mean rank (geo matric): ${parseValue(
              params.data[3].geo_ranking
            )}<br />`;
          }
          
          getHoverTips(
            params,
            {
              task_id: taskId,
              tissue: params.data[3].tissue,
              gene_id: params.data[3].gene,
            },
            seletTools,
            ticket,
            callback
          );
          return "Loading";
        }) as any,
      },
      grid: {
        height: "80%",
        top: "0%",
        left: "120px",
      },
      xAxis: {
        type: "category",
        data: x,
        splitArea: {
          show: true,
        },
        axisLabel: {
          interval: 0,
          rotate: 45,
        },
        nameTextStyle: {
          fontSize: 20,
        },
      },
      yAxis: {
        type: "category",
        data: y,
        splitArea: {
          show: true,
        },
        axisLabel: {
          interval: 0,
        },
        nameTextStyle: {
          fontSize: 20,
        },
      },
      visualMap: {
        min: -2,
        max: 6,
        calculable: false,
        orient: "horizontal",
        left: "center",
        bottom: "0",
        show: false,
        dimension: 2,
        inRange: {
          color: [
            "rgb(170,170,170)",
            "white",
            "rgb(239,202,153)",
            "rgb(240,197,170)",
            "rgb(227,152,128)",
            "rgb(221,127,116)",
            "rgb(215,112,107)",
            "rgb(207,91,93)",
            "rgb(198,67,79)",
          ], // 自定义颜色范围
        },
      },
      series: [
        {
          name: "Info",
          type: "heatmap",
          data: dataList,
          label: {
            show: false,
            formatter: (params: any) => {
              return params.data[2];
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          itemStyle: {
            borderColor: "rgba(0, 0, 0, 0.03)",
            borderWidth: 1,
            borderType: "solid",
          },
        },
      ],
    }),
    [dataList, isOffline, taskId, x, y]
  );

  return (
    <Spin tip="Loading..." spinning={isLoadingForHeatPlot}>
      <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
        <Container len={x.length}>
          {/* {query.isLoading ? (
        <CoverLoading />
      ) : ( */}
          {x.length > 0 && (
            <Chart
              option={option}
              ref={chartRef}
              opts={{ renderer: "svg" } as any}
            />
          )}

          {/* )} */}
        </Container>
        {/* <div>
          <ul>
            <li>
              <ColocDiv colorValue={"rgb(170,170,170)"} />
              (-2): qtl{">"}1e-6
            </li>
            <li>
              <ColocDiv colorValue={"white"} />
              (-1):qtl{"<"}1e-6，colocalization analysis but no results
            </li>
            <li>
              <ColocDiv colorValue={"rgb(240,197,170)"} />
              (1): qtl{"<"}1e-6，colocalization analysis has a tool with results
            </li>
            <li>
              <ColocDiv colorValue={"rgb(227,152,128)"} />
              (2): qtl{"<"}1e-6，colocalization analysis has two tools with
              results
            </li>
            <li>
              <ColocDiv colorValue={"rgb(221,127,116)"} />
              (3): qtl{"<"}1e-6，colocalization analysis has three tools with
              results
            </li>
            <li>
              <ColocDiv colorValue={"rgb(215,112,107)"} />
              (4): qtl{"<"}1e-6，colocalization analysis has four tools with
              results
            </li>
            <li>
              <ColocDiv colorValue={"rgb(207,91,93)"} />
              (5): qtl{"<"}1e-6，colocalization analysis has five tools with
              results
            </li>
            <li>
              <ColocDiv colorValue={"rgb(198,67,79)"} />
              (6): qtl{"<"}1e-6，colocalization analysis has six tools with
              results
            </li>
          </ul>
        </div> */}
      </div>
    </Spin>
  );
}

export default HeatChart;
