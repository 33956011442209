import { AbstractEnvSelect } from '@jswork/env-select';

export class Env extends AbstractEnvSelect {
    /**
     * Auto select env by current url.
     * @returns {Environment} The target env string.
     */
    static select() {
        // const pathname = window.location.pathname;
        const url = window.location.href;
        if (url.indexOf('/production/') > -1) {
            return 'production';
        }
        return 'beta';
    }
}
