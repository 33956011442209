import React from "react";
import { Container } from "../tasks";
import { Divider, Table, Typography } from "antd";
const { Title, Paragraph, Text, Link } = Typography;

export default function ReferenceContent() {
  const dataSource = [
    [
      "eCAVIAR",
      "eQTL and GWAS CAusal Variants Identification in Associated Regions",
      `<div><a href='https://github.com/fhormoz/caviar' target='blank'>Source Code</a></div>
      <div><a href='https://www.cell.com/ajhg/fulltext/S0002-9297(16)30439-6' target='blank'>Paper</a></div>
      <div><a href='http://genetics.cs.ucla.edu/caviar/' target='blank'>Website</a></div>`,
    ],
    [
      "SMR",
      "Summary Mendelian Randomization analysis	",
      `<div><a href='https://yanglab.westlake.edu.cn/software/smr/' target='blank'>Website</a></div>
      <div><a href='https://www.nature.com/articles/ng.3538' target='blank'>Paper</a></div>`,
    ],
    [
      "fastEnloc",
      "Fast enrichment estimation aided colocalization analysis	",
      `<div><a href='https://github.com/xqwen/fastenloc'>Source Code</a></div>
      <div><a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1006646' target='blank'>Paper</a></div>`,
    ],
    [
      "coloc",
      "The coloc package can be used to perform genetic colocalisation analysis of two potentially related phenotypes, to ask whether they share common genetic causal variant(s) in a given region.",
      `<div><a href='https://github.com/chr1swallace/coloc' target='blank'>Source Code</a></div>
      <div><a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009440' target='blank'>Paper</a></div>
      <div><a href='https://chr1swallace.github.io/coloc/' target='blank'>Website</a></div>`,
    ],
    [
      "FUSION	",
      "Transcriptome-Wide Association Study analysis	",
      `<div><a href='https://github.com/gusevlab/fusion_twas' target='blank'>Source Code</a></div>
      <div><a href='https://www.nature.com/articles/ng.3506' target='blank'>Paper</a></div>
      <div><a href='http://gusevlab.org/projects/fusion/' target='blank'>Website</a></div>`,
    ],
    [
      "PrediXcan",
      "Integrative gene expression prediction analysis	",
      `<div><a href='https://github.com/hakyimlab/MetaXcan' target='blank'>Source Code</a></div>
      <div><a href='https://www.nature.com/articles/ng.3367' target='blank'>Paper</a></div>`,
    ],
    [
      "LocusCompare v1",
      "Visualizing the colocalization of association summary statistics	",
      `<div><a href='http://locuscompare.com/' target='blank'>Website</a></div>`,
    ],
    [
      "1000 genomes",
      "Reference panel for genetic variation information	",
      `<div><a href='https://www.internationalgenome.org/' target='blank'>Website</a></div>`,
    ],

    [
      "PLINK(v1.9)",
      "Whole-genome association and linkage analysis	",
      `<div><a href='https://www.cog-genomics.org/plink2' target='blank'>Documentation</a></div>`,
    ],
    [
      "FINEMAP",
      "Fine-mapping analysis	",
      `<div><a href='http://christianbenner.com' target='blank'>Documentation</a></div>`,
    ],
    [
      "GTEx",
      "Gene expression data across human tissues	",
      "<div><a href='https://gtexportal.org/home/' target='blank'>Documentation</a></div>",
    ],
    [
      "GWAS catalog",
      "Catalog of published genome-wide association studies	",
      `<div><a href='https://www.ebi.ac.uk/gwas/' target='blank'>Documentation</a></div>`,
    ],
    [
      "eQTL Catalogue",
      "Catalog of published expression quantitative trait loci	",
      `<div><a href='https://www.ebi.ac.uk/eqtl/' target='blank'>Documentation</a></div>`,
    ],
    [
      "HAPGEN2",
      "Haplotype generation for simulation and imputation studies	",
      `<div><a href='https://mathgen.stats.ox.ac.uk/genetics_software/hapgen/hapgen2.html' target='blank'>Documentation</a></div>`,
    ],
    [
      "BCFtools",
      "Tools for variant calling and genotyping",
      `<div><a href='https://samtools.github.io/bcftools/' target='blank'>Documentation</a></div>`,
    ],
  ].map((item, index) => ({
    key: index,
    name: item[0],
    info: item[1],
    link: item[2],
  }));

  const columns = [
    {
      title: "Data source/tool",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "info",
      key: "info",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (_: any) => <div dangerouslySetInnerHTML={{ __html: _ }} />,
    },
  ];

  return (
    <Container>
      <Typography>
        <Title level={3} style={{ textAlign: 'center' }}>Reference</Title>
        <Divider />
        <Paragraph>
          Locuscompare2 makes use of the following software and datasets. We are
          grateful for the contribution of researchers behind these resources.
          Please contact us if we have missed a citation.
        </Paragraph>
      </Typography>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </Container>
  );
}
