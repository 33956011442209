import {
  Table,
  Input,
  Tag,
  Card,
  Col,
  Row,
  Statistic,
  Select,
  Spin,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IGenes, ILocus, ITableItem } from "../../../../report";
import {
  getJsonPData,
  GET_PLOTS_REPORT,
  localtionDir,
} from "../../../../util/jsonp";
import { locusDatas, snpDetail } from "src/service/api/home";
import GeneTpmComponent from "./GeneTpmComponent";
import _ from "lodash";
import ScatterPlotComponent from "./ScatterPlotComponent";
import { genome_data_merged } from "src/util/chromosome";
import { THRESHOLD_MAP_DETIAL } from "../ResultPage";
const { Search } = Input;
const POPU_LIST = [
  { label: "AFR", value: "AFR" },
  { label: "AMR", value: "AMR" },
  { label: "EUR", value: "EUR" },
  { label: "EAS", value: "EAS" },
  { label: "SAS", value: "SAS" },
];

export default React.memo(
  function LocusPlotComponent(props: {
    gene: Partial<IGenes> | any;
    trait: string;
    tissue: string;
    recordId: string;
    population: string;
    setLocusPreocess: any;
    isOffline: boolean;
    isLoadingForLocusPlot: boolean;
    locusComponentShow: boolean;
    tissueName: string;
    traitName: string;
    snps: any[];
  }) {
    const {
      gene,
      trait,
      tissue,
      setLocusPreocess,
      population,
      isOffline,
      snps,
      recordId,
      isLoadingForLocusPlot,
      locusComponentShow,
      tissueName,
      traitName,
    } = props;
    // 存储一个gene的所有plot数据信息
    const [geneInfo, setGeneInfo] = useState<ILocus[]>([]);
    const [dataSource, setDataSource] = useState<ITableItem[]>([]);
    const [targetSnp, setTargetSnp] = useState<any>();
    const [selectSnp, setSelectSnp] = useState<string>();
    const [selectPop, setSelectPop] = useState<string>("EUR");
    const [isLoadingForLocusData, setIsloadingForLocusData] =
      useState<boolean>(false);
    const [isLoadingForSnpDetail, setIsloadingForSnpDetail] =
      useState<boolean>(false);
    const onChange = useCallback(async (value: string) => {
      await setGeneInfo([]);
      await setSelectSnp(value);
    }, []);
    useEffect(() => {
      setSelectSnp(gene?.h_target_rsid);
    }, [gene?.h_target_rsid, snps, isOffline]);
    useEffect(() => {
      isLoadingForLocusPlot && setGeneInfo([]);
    }, [isLoadingForLocusPlot]);

    useEffect(() => {
      (async function () {
        if (!recordId || !selectSnp) return;
        try {
          setIsloadingForSnpDetail(true);
          const snpDet: any = (
            await snpDetail({
              colot_record_id: recordId,
              rs_id: selectSnp,
            })
          )?.data?.data;
          setTargetSnp(snpDet);
          setIsloadingForSnpDetail(false);
        } catch (error) {
          setIsloadingForSnpDetail(false);
        }
      })();
    }, [recordId, selectSnp, trait]);

    const onSearch = (value: string) => {
      setDataSource(
        geneInfo
          .filter(
            (item) => item.category !== "r2_one" && item.snp.indexOf(value) > -1
          )
          .map((item, index) => {
            return {
              key: index,
              snp: item.snp,
              chromosome: item.chrom,
              position: item.position,
              y_axis: item.eqtl_pvalue,
              x_axis: item.pvalue,
              ldr2: item.r2,
            };
          })
      );
    };

    useEffect(() => {
      if (gene?.gene_id && trait) {
        if (isOffline) {
          getJsonPData(
            GET_PLOTS_REPORT,
            `${trait}/${tissue}/${gene?.gene_id}.json`
          ).then((data: ILocus[]) => {
            setGeneInfo(data);

            setDataSource(
              data
                .filter((item) => item.category !== "r2_one")
                .map((item, index) => {
                  if (item.category === "target") {
                    setTargetSnp(item);
                    !selectSnp && setSelectSnp(gene?.h_target_rsid);
                  }
                  return {
                    key: index,
                    snp: item.snp,
                    chromosome: item.chrom,
                    position: item.position,
                    y_axis: item.eqtl_pvalue,
                    x_axis: item.pvalue,
                    ldr2: item.r2,
                  };
                })
            );
          });
        } else {
          (async function () {
            if (!recordId || !selectSnp) return;
            try {
              setIsloadingForLocusData(true);
              setDataSource([]);
              const snpList: any = (
                await locusDatas({
                  gene_id: gene?.gene_id,
                  colot_record_id: recordId,
                  snp: selectSnp,
                  population: selectPop,
                })
              )?.data?.data;
              setGeneInfo(
                snpList?.list.map((item: any) => {
                  let category = "";
                  if (item.r2 > 1) {
                    category = "target";
                  } else if (item.r2 >= 0.8) {
                    category = "r2_five";
                  } else if (item.r2 >= 0.6) {
                    category = "r2_four";
                  } else if (item.r2 >= 0.4) {
                    category = "r2_thr";
                  } else if (item.r2 >= 0.2) {
                    category = "r2_two";
                  } else {
                    category = "r2_one";
                  }
                  return {
                    category,
                    chrom: snpList.chrom,
                    eqtl_pvalue: item.eqtl_value,
                    eqtl_snp: item.rsid,
                    position: item.position,
                    pvalue: item.gwas_value,
                    r2: item.r2,
                    snp: item.rsid,
                    var_id_: `chr_${item.position}`,
                  };
                })
              );

              setDataSource(
                snpList?.list
                  ?.filter((item: any) => item.r2 > 0 && item.r2 < 2)
                  ?.map((item: any, index: number) => {
                    return {
                      key: index,
                      snp: item.rsid,
                      chromosome: snpList.chrom,
                      position: item.position,
                      y_axis: item.eqtl_value,
                      x_axis: item.gwas_value,
                      ldr2: item.r2,
                    };
                  })
                  .sort((a: any, b: any) => b.ldr2 - a.ldr2)
              );
              setIsloadingForLocusData(false);
            } catch (error) {
              setIsloadingForLocusData(false);
            }
          })();
        }
      }
    }, [gene, isOffline, recordId, selectPop, selectSnp, tissue, trait]);

    const onSearchSnp = (value: string) => {
      setDataSource(
        geneInfo
          .filter((item) => item.category !== "r2_one")
          .map((item, index) => {
            return {
              key: index,
              snp: item.snp,
              chromosome: item.chrom,
              position: item.position,
              y_axis: item.eqtl_pvalue,
              x_axis: item.pvalue,
              ldr2: item.r2,
            };
          })
          .filter((item: any) => {
            for (let key in item) {
              if (
                key !== "key" &&
                (item[key].toString() as any).indexOf(value) > -1
              ) {
                return true;
              }
            }
            return false;
          })
      );
    };

    const columns = [
      {
        title: "rsID",
        dataIndex: "snp",
        key: "snp",
        fixed: "left",
      },
      {
        title: "Chromosome",
        dataIndex: "chromosome",
        key: "chromosome",
      },
      {
        title: "Position",
        dataIndex: "position",
        key: "position",
        render: (text: any, item: any) =>
          isOffline
            ? text
            : item.position -
              genome_data_merged[item.chromosome - 1].genome_start,
      },
      {
        title: "P-value(x-axis)",
        dataIndex: "x_axis",
        key: "x_axis",
      },
      {
        title: "P-value(y-axis)",
        dataIndex: "y_axis",
        key: "y_axis",
      },
      {
        title: "LD(r2)",
        dataIndex: "ldr2",
        key: "ldr2",
        sortDirections: ["descend", "ascend"],
        sorter: (a: any, b: any) => a.ldr2 - b.ldr2,
        fixed: "right",
      },
    ];
    const columns_gene = [
      {
        title: "Tool Name",
        dataIndex: "tool_name",
        key: "tool_name",
      },
      {
        title: "Tool report column name",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "value",
        dataIndex: "value",
        key: "value",
      },
    ];

    const tooltipInfo = useCallback(
      (item: any) => ({
        snp: item.snp,
        chrom: item.chrom,
        position:
          item.position - genome_data_merged[item.chrom - 1].genome_start,
        eqtl_pvalue: item.eqtl_pvalue,
        gwas_pvalue: item.pvalue,
        var_id: item.var_id_,
        category: item.category,
        ...item,
      }),
      []
    );

    const tooltip = useMemo(
      () => ({
        show: true,
        triggerOn: "click",
        position: "top",
        formatter: function (params: any) {
          return ` <span style="font-weight:600;font-size:16px">${params.data[3].snp}</span><br />
          <span style="font-weight:600">gwas(-log10(P))</span>: ${params.data[3].gwas_pvalue}<br />
          <span style="font-weight:600">eQTL(-log10(P))</span>: ${params.data[3].eqtl_pvalue}<br />
          <span style="font-weight:600">position</span>: ${params.data[3].position}<br />
          <span style="font-weight:600">chrom</span>: ${params.data[3].chrom}<br />`;
        },
      }),
      []
    );
    const visualMap = useMemo(
      () => ({
        type: "piecewise",
        min: 0,
        max: 1,
        orient: "horizontal",
        left: "center",
        bottom: "0",
        dimension: 4,
        range: [0, 1],
        inRange: {
          color: ["#0c028b", "#87ceeb", "#186400", "#f8a402", "#f50703"], // 自定义颜色范围
        },
        outOfRange: {
          color: ["rgb(150, 50, 184)"], // 自定义颜色范围
        },
      }),
      []
    );

    const gwasData = useMemo(
      () =>
        geneInfo.map((item: ILocus) => [
          isOffline
            ? item.position
            : item.position - genome_data_merged[item.chrom - 1].genome_start,
          isOffline ? -Math.log10(item.pvalue) : item.pvalue,
          item.category,
          tooltipInfo(item),
          item.r2 || 0,
        ]),
      [geneInfo, isOffline, tooltipInfo]
    );
    const eqtlData = useMemo(
      () =>
        geneInfo.map((item: ILocus) => [
          isOffline
            ? item.position
            : item.position - genome_data_merged[item.chrom - 1].genome_start,
          isOffline ? -Math.log10(item.pvalue) : item.eqtl_pvalue,
          item.category,
          tooltipInfo(item),
          item.r2 || 0,
        ]),
      [geneInfo, isOffline, tooltipInfo]
    );
    const pvalueData = useMemo(
      () =>
        geneInfo.map((item: ILocus) => [
          isOffline ? -Math.log10(item.pvalue) : item.pvalue,
          isOffline ? -Math.log10(item.eqtl_pvalue) : item.eqtl_pvalue,
          item.category,
          tooltipInfo(item),
          item.r2 || 0,
        ]),
      [geneInfo, isOffline, tooltipInfo]
    );

    return (
      <>
        {locusComponentShow && (
          <>
            <Spin
              tip="Loading..."
              spinning={isLoadingForLocusPlot || isLoadingForLocusData}
            >
              <Row className="tissue-row-title ">
                <Col span={4} className="font-weight-format">
                  SNP Select :
                </Col>
                <Col>
                  <Select
                    showSearch
                    value={selectSnp}
                    className="StudySelect"
                    placeholder="Select snp"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearchSnp}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={
                      isOffline
                        ? geneInfo.map((item) => {
                            return {
                              value: item.snp,
                              label: item.snp,
                            };
                          })
                        : snps.map((item) => {
                            return {
                              value: item,
                              label: item,
                            };
                          })
                    }
                  />
                </Col>
              </Row>
              <Row className="tissue-row-title">
                <Col span={4} className="font-weight-format">
                  Population Select :
                </Col>
                <Col>
                  <Select
                    showSearch
                    value={selectPop}
                    className="StudySelect"
                    placeholder="Search population"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      (option?.label ?? "").includes(input)
                    }
                    options={
                      isOffline
                        ? [
                            {
                              label: population,
                              value: population,
                            },
                          ]
                        : POPU_LIST
                    }
                    onChange={(_) => setSelectPop(_)}
                  />
                </Col>
              </Row>
              {selectSnp !== gene?.h_target_rsid &&
                geneInfo.length > 0 &&
                isOffline && (
                  <div>
                    <code>
                      <a
                        href="https://github.com/boxiangliu/locuscomparer"
                        target="_blank"
                        rel="noreferrer"
                      >{`library(locuscomparer)`}</a>
                    </code>
                    <br />
                    <code>{`gene_gwas <- read.csv("${localtionDir + trait}/${
                      gene?.gene_id
                    }.tsv",TRUE,"\\t")`}</code>
                    <br />
                    <code>{`gene_eqtl <- read.csv("${localtionDir + trait}/${
                      gene?.gene_id
                    }.tsv",TRUE,"\\t")`}</code>
                    <br />
                    <code>{`names(gene_gwas)<-c("rsid","pval","pvalue")`}</code>
                    <br />
                    <code>{`names(gene_eqtl)<-c("rsid","pvalue","pval")`}</code>
                    <br />
                    <code>{`locuscompare(in_fn1 = gene_gwas, in_fn2 = gene_eqtl, title1 = "${trait} GWAS", title2 = "${tissue} eQTL",snp="${selectSnp}", population="${population}")`}</code>
                  </div>
                )}

              <Row
                style={{
                  display:
                    selectSnp === gene?.h_target_rsid || !isOffline
                      ? "flex"
                      : "none",
                      fontSize:'150%'
                }}
                className="tissue-row-title juxtify-content-center font-weight-format"
              >
                LocusCompare Plot
              </Row>
              <div
                style={{
                  height: "740px",
                  width: "100%",
                  display:
                    selectSnp === gene?.h_target_rsid || !isOffline
                      ? "flex"
                      : "none",
                }}
              >
                {!isLoadingForLocusPlot && (
                  <div className="LocusWrapper">
                    <div id="pvalue-plot">
                      <ScatterPlotComponent
                        dataList={pvalueData}
                        tooltip={tooltip}
                        option={{
                          width: 500,
                          height: 670,
                          xName: `${traitName} GWAS -log10(P)`,
                          yName: `${tissueName} eQTL -log10(P)`,
                          visualMap,
                        }}
                      />
                    </div>

                    <div>
                      <div id="gwas-plot">
                        <ScatterPlotComponent
                          dataList={gwasData}
                          tooltip={tooltip}
                          option={{
                            width: 500,
                            height: 330,
                            xName: `Chromosome ${geneInfo[0]?.chrom}（Mb）`,
                            yName: `${traitName} GWAS -log10(P)`,
                          }}
                        />
                      </div>
                      <div id="eqtl-plot">
                        <ScatterPlotComponent
                          dataList={eqtlData}
                          tooltip={tooltip}
                          option={{
                            width: 500,
                            height: 330,
                            xName: `Chromosome ${geneInfo[0]?.chrom}（Mb）`,
                            yName: `${tissueName} eQTL -log10(P)`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Spin>
            {gene?.gene_id && !isOffline && (
              <GeneTpmComponent gene_id={gene?.gene_id} />
            )}

            <div className="upsetSpin">
              <Spin tip="Loading..." spinning={isLoadingForLocusPlot}>
                <Row className="font-weight-format" style={{fontSize:'150%'}}>GENE Information List</Row>
                <Row className="tableWrapper" gutter={16}>
                  <Col span={8}>
                    <Card style={{ height: "100%", borderRadius: "5px" }}>
                      <Statistic title="GENE ID" value={gene?.gene_id} />
                      <Statistic title="GENE Symbol" value={gene?.gene_name} />
                      <Statistic title="Chromosome" value={gene?.chrom} />
                      <Statistic title="TSS" value={gene?.position} />
                    </Card>
                  </Col>
                  <Col span={16}>
                    <Card style={{ height: "100%", borderRadius: "5px" }}>
                      <Table
                        dataSource={Object.entries(THRESHOLD_MAP_DETIAL)
                          ?.filter((item: any) => gene?.[item[0]] > -1)
                          ?.map((item: any, index) => {
                            return {
                              key: index,
                              tool_name: item[1].label,
                              type: item[1].pLabel,
                              value: gene[item[0]],
                            };
                          })}
                        columns={columns_gene}
                        pagination={false}
                      />
                    </Card>
                  </Col>
                </Row>
              </Spin>
            </div>
            {(selectSnp === gene?.h_target_rsid || !isOffline) && (
              <>
                {" "}
                <Row className="font-weight-format" style={{fontSize:'150%'}}>SNP Information List </Row>
                <Row className="tableWrapper" gutter={16}>
                  <Col span={8}>
                    <Spin
                      tip="Loading..."
                      spinning={isLoadingForSnpDetail || isLoadingForLocusPlot}
                    >
                      <Card
                        style={{
                          height: "100%",
                          borderRadius: "5px",
                        }}
                      >
                        <Statistic
                          title="Chromosome"
                          value={targetSnp?.chrom}
                        />
                        <Statistic
                          title="Position"
                          value={targetSnp?.position}
                        />
                        <Statistic
                          title="rs ID"
                          value={
                            `rs${targetSnp?.rs_id || ""}` || gene?.h_target_rsid
                          }
                        />
                        <Statistic
                          title="Reference SNP"
                          value={gene?.ref || targetSnp?.reference_snp}
                        />
                        <Statistic
                          title="Alternate SNP"
                          value={gene?.alt || targetSnp?.alternate_snp}
                        />
                        <Statistic
                          title="Allele Frequency"
                          value={gene?.AF || targetSnp?.allele_frequency}
                        />
                        <Statistic
                          title="AFR Frequency"
                          value={gene?.AFR_AF || targetSnp?.afr_frequency}
                        />
                        <Statistic
                          title="AMR Frequency"
                          value={gene?.AMR_AF || targetSnp?.amr_frequency}
                        />
                        <Statistic
                          title="EAS Frequency"
                          value={gene?.EAS_AF || targetSnp?.eas_frequency}
                        />
                        <Statistic
                          title="EUR Frequency"
                          value={gene?.EUR_AF || targetSnp?.eur_frequency}
                        />
                        <Statistic
                          title="SAS Frequenc"
                          value={gene?.SAS_AF || targetSnp?.sas_frequency}
                        />
                      </Card>
                    </Spin>
                  </Col>

                  <Col span={16}>
                    <Card style={{ height: "100%", borderRadius: "5px" }}>
                      <Search
                        allowClear
                        className="StudySelect"
                        enterButton
                        placeholder="Select snp"
                        onSearch={onSearch}
                        style={{ marginBottom: "20px" }}
                      />
                      <Table
                        loading={isLoadingForLocusData || isLoadingForLocusPlot}
                        dataSource={dataSource}
                        columns={columns as any}
                        scroll={{ x: "100%" }}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </>
    );
  },
  (prevProps: any, nextProps: any) => {
    if (!_.isEqual(prevProps.gene, nextProps.gene)) return false;
    return true;
  }
);
