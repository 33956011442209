import { useCallback, useMemo, useState } from "react";
import { round } from "lodash";

function getPercent(e: ProgressEvent): number {
  return round((e.loaded / e.total) * 100, 1);
}
function useUploadProgress(): {
  progress: number | undefined;
  reset: () => void;
  onUploadProgress: (e: ProgressEvent) => void;
} {
  const [progress, setProgress] = useState<number | undefined>();

  const onUploadProgress = useCallback((e: ProgressEvent) => {
    setProgress(getPercent(e));
  }, []);

  const reset = useCallback(() => {
    setProgress(undefined);
  }, []);

  return useMemo(
    () => ({
      progress,
      reset,
      onUploadProgress,
    }),
    [progress, reset, onUploadProgress]
  );
}

export default useUploadProgress;
