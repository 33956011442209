export interface IReport {
  trait: string;
  tissue: string;
  tool_name: string;
  report_path: string;
  study1: string;
  population: string;
}

export interface IGenes {
  gene_id: string;
  intact_probability: number;
  chrom: number;
  position: number;
  eqtl_path: string;
  gwas_path: string;
  tool_name: string;
  gene_name: string;
  coloc: number;
  ecaviar: number;
  fastenloc: number;
  predixcan: number;
  smr: number;
  AF: number;
  AFR_AF: number;
  AMR_AF: number;
  EAS_AF: number;
  EUR_AF: number;
  SAS_AF: number;
  alt: string;
  ref: string;
  h_target_rsid: string;
}

export interface IManhattan {
  snp: string;
  chrom: number;
  position: number;
  pvalue: number;
  var_id: string;
  category: string;
}

export interface ILocus {
  snp: string;
  eqtl_snp: string;
  pvalue: number;
  eqtl_pvalue: number;
  position: number;
  chrom: number;
  r2: number;
  category: string;
  var_id_: string;
}

export interface IBeta {
  snp: string;
  eqtl_snp: string;
  gwas_beta: number;
  eqtl_beta: number;
  eqtl_se: number;
  gwas_se: number;
  position: number;
  chrom: number;
  r2: number;
  category: string;
  var_id_: string;
  pearson_corr: any;
  pearson_pval:any;
  // slope: number;
  // intercept: number;
}



export const REPORTS_FILE_NAME = "reports_path.json";
export const GENES_FILE_NAME = "genes_path.json";
export const MANHATTAN_FILE_NAME = "data_mant.json";

export interface ITraitOption {
  value: string;
  label: string;
  population: string;
}
export interface ISelect {
  title: string;
  value: string;
  key: string;
  children?: [];
}
export interface ITableItem {
  key: number;
  snp: string;
  chromosome: number;
  position: number;
  y_axis: number;
  x_axis: number;
  ldr2: number;
}
