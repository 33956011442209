import {
  Table,
  Input,
  Tag,
  Card,
  Col,
  Row,
  Statistic,
  Select,
  Spin,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IGenes, IBeta, ITableItem } from "../../../../report";
import {
  getJsonPData,
  GET_PLOTS_REPORT,
  localtionDir,
} from "../../../../util/jsonp";
import { getEqtlChart } from "src/service/api/home";

import _ from "lodash";
import BetaScatterPlotComponent from "./BetaScatterPlotComponent";
import { genome_data_merged } from "src/util/chromosome";
import { THRESHOLD_MAP_DETIAL } from "../ResultPage";
const { Search } = Input;
const POPU_LIST = [
  { label: "AFR", value: "AFR" },
  { label: "AMR", value: "AMR" },
  { label: "EUR", value: "EUR" },
  { label: "EAS", value: "EAS" },
  { label: "SAS", value: "SAS" },
];

let slope: number;
let intercept: number;

export default React.memo(
  function CisEqtlComponent(props: {
    gene: Partial<IGenes> | any;
    trait: string;
    tissue: string;
    recordId: string;
    population: string;
    setLocusPreocess: any;
    isOffline: boolean;
    isLoadingForLocusPlot: boolean;
    locusComponentShow: boolean;
    tissueName: string;
    traitName: string;
    snps: any[];
  }) {
    const {
      gene,
      trait,
      tissue,
      setLocusPreocess,
      population,
      isOffline,
      snps,
      recordId,
      isLoadingForLocusPlot,
      locusComponentShow,
      tissueName,
      traitName,
    } = props;
    // 存储一个gene的所有plot数据信息
    const [geneInfo, setGeneInfo] = useState<IBeta[]>([]);
    // const [lineplot, setLineplot] = useState<ILine[]>([]);
    const [dataSource, setDataSource] = useState<ITableItem[]>([]);
    const [targetSnp, setTargetSnp] = useState<any>();
    const [pearson, setpearson] = useState({ pearson_corr: null, pearson_pval: null });
    const [selectSnp, setSelectSnp] = useState<string>();
    const [selectPop, setSelectPop] = useState<string>("EUR");
    const [isLoadingForLocusData, setIsloadingForLocusData] =
      useState<boolean>(false);
    // const [isLoadingForSnpDetail, setIsloadingForSnpDetail] =
    //   useState<boolean>(false);
    
    const onChange = useCallback(async (value: string) => {
      await setGeneInfo([]);
      await setSelectSnp(value);
    }, []);
    useEffect(() => {
      setSelectSnp(gene?.h_target_rsid);
    }, [gene?.h_target_rsid, snps, isOffline]);
    useEffect(() => {
      isLoadingForLocusPlot && setGeneInfo([]);
    }, [isLoadingForLocusPlot]);

    // useEffect(() => {
    //   (async function () {
    //     if (!recordId || !selectSnp) return;
    //     try {
    //       setIsloadingForSnpDetail(true);
    //       const snpDet: any = (
    //         await snpDetail({
    //           colot_record_id: recordId,
    //           rs_id: selectSnp,
    //         })
    //       )?.data?.data;
    //       setTargetSnp(snpDet);
    //       setIsloadingForSnpDetail(false);
    //     } catch (error) {
    //       setIsloadingForSnpDetail(false);
    //     }
    //   })();
    // }, [recordId, selectSnp, trait]);

    const onSearch = (value: string) => {
      setDataSource(
        geneInfo
          .filter(
            (item) => item.category !== "r2_one" && item.snp.indexOf(value) > -1
          )
          .map((item, index) => {
            return {
              key: index,
              snp: item.snp,
              chromosome: item.chrom,
              position: item.position,
              y_axis: item.eqtl_beta,
              x_axis: item.gwas_beta,
              ldr2: item.r2,
              gwas_se: item.gwas_se,
              eqtl_se: item.eqtl_se,
             
            };
          })
      );
    };

    useEffect(() => {
      if (gene?.gene_id && trait) {
        if (isOffline) {
          getJsonPData(
            GET_PLOTS_REPORT,
            `${trait}/${tissue}/${gene?.gene_id}.json`
          ).then((data: IBeta[]) => {
            setGeneInfo(data);

            setDataSource(
              data
                .filter((item) => item.category !== "r2_one")
                .map((item, index) => {
                  if (item.category === "target") {
                    setTargetSnp(item);
                    !selectSnp && setSelectSnp(gene?.h_target_rsid);
                  }
                  return {
                    key: index,
                    snp: item.snp,
                    chromosome: item.chrom,
                    position: item.position,
                    y_axis: item.eqtl_beta,
                    x_axis: item.gwas_beta,
                    ldr2: item.r2,
                    gwas_se: item.gwas_se,
                    eqtl_se: item.eqtl_se,
                    };
                })
            );
          });
        } else {
          (async function () {
            if (!recordId || !selectSnp) return;
            try {
              setIsloadingForLocusData(true);
              setDataSource([]);
              const snpList: any = (
                await getEqtlChart({
                  gene_id: gene?.gene_id,
                  colot_record_id: recordId,
                  snp: selectSnp,
                  population: selectPop,
                })
              )?.data?.data;
              console.log("snpListdsszfs:", snpList)
              setGeneInfo(
                snpList?.list.map((item: any) => {
                  let category = "";
                  if (item.r2 > 1) {
                    category = "target";
                  } else if (item.r2 >= 0.8) {
                    category = "r2_five";
                  } else if (item.r2 >= 0.6) {
                    category = "r2_four";
                  } else if (item.r2 >= 0.4) {
                    category = "r2_thr";
                  } else if (item.r2 >= 0.2) {
                    category = "r2_two";
                  } else {
                    category = "r2_one";
                  }
                  return {
                    category,
                    chrom: snpList.chrom,
                    eqtl_beta: item.eqtl_value,
                    eqtl_snp: item.rsid,
                    position: item.position,
                    gwas_beta: item.gwas_value,
                    gwas_se: item.gwas_se,
                    eqtl_se: item.eqtl_se,
                    r2: item.r2,
                    snp: item.rsid,
                    var_id_: `chr_${item.position}`,
                  };
                })
              );

              slope = snpList.slope;
              intercept = snpList.intercept;
              setpearson({
                pearson_corr: snpList.pearson_corr,
                pearson_pval: snpList.pearson_pval,
              });
              console.log("pearson:", pearson)
              // setLineplot(
              //   snpList?.slope.map((item: any) => {
              //     return {
              //       slope: snpList.slope,
              //       intercept: snpList.intercept,
              //     };
              //   })
              // );
              // console.log("slope:", slope)
              // console.log("intercept:", intercept)
              setDataSource(
                snpList?.list
                  ?.filter((item: any) => item.r2 > 0 && item.r2 < 2)
                  ?.map((item: any, index: number) => {
                    return {
                      key: index,
                      snp: item.rsid,
                      chromosome: snpList.chrom,
                      position: item.position,
                      y_axis: item.eqtl_value,
                      x_axis: item.gwas_value,
                      ldr2: item.r2,
                      gwas_se: item.gwas_se,
                      eqtl_se: item.eqtl_se,
                      
                    };
                  })
                  .sort((a: any, b: any) => b.ldr2 - a.ldr2)
              );
              setIsloadingForLocusData(false);
            } catch (error) {
              setIsloadingForLocusData(false);
            }
          })();
        }
      }
    }, [gene, isOffline, recordId, selectPop, selectSnp, tissue, trait]);

    const onSearchSnp = (value: string) => {
      setDataSource(
        geneInfo
          .filter((item) => item.category !== "r2_one")
          .map((item, index) => {
            return {
              key: index,
              snp: item.snp,
              chromosome: item.chrom,
              position: item.position,
              y_axis: item.eqtl_beta,
              x_axis: item.gwas_beta,
              ldr2: item.r2,
              eqtl_se: item.eqtl_se,
              gwas_se: item.gwas_se,
            };
          })
          .filter((item: any) => {
            for (let key in item) {
              if (
                key !== "key" &&
                (item[key].toString() as any).indexOf(value) > -1
              ) {
                return true;
              }
            }
            return false;
          })
      );
    };

    const tooltipInfo = useCallback(
      (item: any) => ({
        snp: item.snp,
        chrom: item.chrom,
        position:
          item.position - genome_data_merged[item.chrom - 1].genome_start,
        eqtl_beta: item.eqtl_beta,
        gwas_beta: item.gwas_beta,
        var_id: item.var_id_,
        category: item.category,
        eqtl_se: item.eqtl_se,
        gwas_se: item.gwas_se,
        ...item,
      }),
      []
    );


    const tooltip = useMemo(
      () => ({
        show: true,
        triggerOn: "click",
        position: "top",
        // formatter: function (params: any) {
        //   return ` <span style="font-weight:600;font-size:16px">${params.data[3].snp}</span><br />
        //   <span style="font-weight:600">gwas(-log10(P))</span>: ${params.data[3].gwas_pvalue}<br />
        //   <span style="font-weight:600">eQTL(-log10(P))</span>: ${params.data[3].eqtl_pvalue}<br />
        //   <span style="font-weight:600">position</span>: ${params.data[3].position}<br />
        //   <span style="font-weight:600">chrom</span>: ${params.data[3].chrom}<br />`;
        // },
        formatter: function (params: any) {
          return ` <span style="font-weight:600;font-size:16px">${params.data[3].snp}</span><br />
          <span style="font-weight:600">gwas effect size</span>: ${params.data[3].gwas_beta}<br />
          <span style="font-weight:600">eQTL effect size</span>: ${params.data[3].eqtl_beta}<br />
          <span style="font-weight:600">position</span>: ${params.data[3].position}<br />
          <span style="font-weight:600">chrom</span>: ${params.data[3].chrom}<br />`;
        },


      }),
      []
    );

    const visualMap = useMemo(
      () => ({
        type: "piecewise",
        min: 0,
        max: 1,
        orient: "horizontal",
        left: "center",
        bottom: "0",
        // dimension: 4,
        dimension: 8,
        range: [0, 1],
        inRange: {
          color: ["#0c028b", "#87ceeb", "#186400", "#f8a402", "#f50703"], // 自定义颜色范围
        },
        outOfRange: {
          color: ["rgb(150, 50, 184)"], // 自定义颜色范围
        },
      }),
      []
    );

    // const gwasData = useMemo(
    //   () =>
    //     geneInfo.map((item: ILocus) => [
    //       isOffline
    //         ? item.position
    //         : item.position - genome_data_merged[item.chrom - 1].genome_start,
    //       isOffline ? -Math.log10(item.pvalue) : item.pvalue,
    //       item.category,
    //       tooltipInfo(item),
    //       item.r2 || 0,
    //     ]),
    //   [geneInfo, isOffline, tooltipInfo]
    // );
    // const eqtlData = useMemo(
    //   () =>
    //     geneInfo.map((item: ILocus) => [
    //       isOffline
    //         ? item.position
    //         : item.position - genome_data_merged[item.chrom - 1].genome_start,
    //       isOffline ? -Math.log10(item.pvalue) : item.eqtl_pvalue,
    //       item.category,
    //       tooltipInfo(item),
    //       item.r2 || 0,
    //     ]),
    //   [geneInfo, isOffline, tooltipInfo]
    // );
    const pvalueData = useMemo(
      () =>
        geneInfo.map((item: IBeta) => [
          // isOffline ? -Math.log10(item.pvalue) : item.pvalue,
          // isOffline ? -Math.log10(item.eqtl_pvalue) : item.eqtl_pvalue,
          item.snp,
          isOffline ? item.gwas_beta : item.gwas_beta,
          isOffline ? item.eqtl_beta : item.eqtl_beta,
          isOffline ? item.eqtl_se : item.eqtl_se, 
          isOffline ? item.gwas_se : item.gwas_se, // 加在最后
          item.category,
          // tooltipInfo(item),
          item.r2 || 0,
          // tooltipInfo(item),

        ]),
      // [geneInfo, isOffline, tooltipInfo]
      [geneInfo, isOffline]
    );

    // const line = useMemo(
    //   () =>
    //     lineplot.map((item: ILine) => [
    //       isOffline ? item.slope: item.slope,
    //       isOffline ? item.intercept: item.intercept,
    //     ]),[lineplot, isOffline]
    // )

    return (
      <>
        {locusComponentShow && (
          <>
            <Spin
              tip="Loading..."
              spinning={isLoadingForLocusPlot || isLoadingForLocusData}
            >
              <Row className="tissue-row-title ">
                <Col span={4} className="font-weight-format">
                  SNP Select :
                </Col>
                <Col>
                  <Select
                    showSearch
                    value={selectSnp}
                    className="StudySelect"
                    placeholder="Select snp"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearchSnp}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={
                      isOffline
                        ? geneInfo.map((item) => {
                            return {
                              value: item.snp,
                              label: item.snp,
                            };
                          })
                        : snps.map((item) => {
                            return {
                              value: item,
                              label: item,
                            };
                          })
                    }
                  />
                </Col>
              </Row>
              <Row className="tissue-row-title">
                <Col span={4} className="font-weight-format">
                  Population Select :
                </Col>
                <Col>
                  <Select
                    showSearch
                    value={selectPop}
                    className="StudySelect"
                    placeholder="Search population"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      (option?.label ?? "").includes(input)
                    }
                    options={
                      isOffline
                        ? [
                            {
                              label: population,
                              value: population,
                            },
                          ]
                        : POPU_LIST
                    }
                    onChange={(_) => setSelectPop(_)}
                  />
                </Col>
              </Row>
              {selectSnp !== gene?.h_target_rsid &&
                geneInfo.length > 0 &&
                isOffline && (
                  <div>
                    <code>
                      <a
                        href="https://github.com/boxiangliu/locuscomparer"
                        target="_blank"
                        rel="noreferrer"
                      >{`library(locuscomparer)`}</a>
                    </code>
                    <br />
                    <code>{`gene_gwas <- read.csv("${localtionDir + trait}/${
                      gene?.gene_id
                    }.tsv",TRUE,"\\t")`}</code>
                    <br />
                    <code>{`gene_eqtl <- read.csv("${localtionDir + trait}/${
                      gene?.gene_id
                    }.tsv",TRUE,"\\t")`}</code>
                    <br />
                    <code>{`names(gene_gwas)<-c("rsid","pval","pvalue")`}</code>
                    <br />
                    <code>{`names(gene_eqtl)<-c("rsid","pvalue","pval")`}</code>
                    <br />
                    <code>{`locuscompare(in_fn1 = gene_gwas, in_fn2 = gene_eqtl, title1 = "${trait} GWAS", title2 = "${tissue} eQTL",snp="${selectSnp}", population="${population}")`}</code>
                  </div>
                )}

              <Row
                style={{
                  display:
                    selectSnp === gene?.h_target_rsid || !isOffline
                      ? "flex"
                      : "none",
                      fontSize:'150%'
                }}
                className="tissue-row-title juxtify-content-center font-weight-format"
              >
                Effect Size Plot
              </Row>
              <div
                style={{
                  height: "740px",
                  width: "100%",
                  display:
                    selectSnp === gene?.h_target_rsid || !isOffline
                      ? "flex"
                      : "none",
                }}
              >
                {!isLoadingForLocusPlot && (
                  <div className="LocusWrapper">
                    <div id="pvalue-plot">
                      <BetaScatterPlotComponent
                        dataList={pvalueData}
                        Pearson={pearson}
                        // tooltip={tooltip}
                        slope = {slope}
                        intercept = {intercept}
                        option={{
                          width:  640,
                          height: 570,
                          xName: `${tissueName} eQTL effect size`,
                          yName: `${traitName} GWAS effect size`,
                          visualMap,
                        }}
                      />
                    </div>

                  </div>
                )}
              </div>
            </Spin>

          </>
        )}
      </>
    );
  },
  (prevProps: any, nextProps: any) => {
    if (!_.isEqual(prevProps.gene, nextProps.gene)) return false;
    return true;
  }
);
