import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ResultsPage from "./container/home/analysis/ResultPage";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const isFileOrigin = window.location.origin.indexOf("file") > -1;
console.log(process.env.NODE_ENV);

async function appendAllScript() {
  // ResultsPage  --> single run result page 
  // App          --> home page 
  
  root.render(isFileOrigin ? <ResultsPage isOffline={true} /> : <App />);
}

appendAllScript();


// Google Tag Manager script
function addGoogleTagManager() {
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-EGMSS68ZDE";
  document.head.appendChild(script);

  const inlineScript = document.createElement("script");
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-EGMSS68ZDE');
  `;
  document.head.appendChild(inlineScript);
}

function MyApp() {
  useEffect(() => {
    addGoogleTagManager();
  }, []);

  return isFileOrigin ? <ResultsPage isOffline={true} /> : <App />;
}

root.render(<MyApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
