import { Button, Col, Form, message, Row, Spin } from "antd";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import Input from "src/components/common/Input";
import CountDownButton from "src/components/login/CountDownButton";
import { LOGIN_PASSWORD_PATTERN } from "src/constants/regexp";
import loginApis from "src/service/api/login";
interface ResetPasswordComponentProps {}

type FormDataType = {
  email: string;
  verificationCode: string;
  password: string;
  confirmPassword: string;
};

const COUNT_DOWN_SECONDS = 60;
const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const FormContainer = styled.div`
  width: 400px;
  background-color: #fff;
  padding: 28px;
  border-radius: 10px;
  margin: auto;
`;
const FormHeader = styled.h1`
  text-align: center;
  font-size: 32px;
  margin-bottom: 64px;
`;
const FormC = styled(Form)`
  width: 368px;
`;
const InputFormItem = styled(Form.Item)`
  .ant-form-item-explain {
    padding: 2px 0 8px 0;
  }
`;
const ToLoginContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
function RegisterComponent(props: ResetPasswordComponentProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState<boolean>(false);

  const handleFinish = useCallback(async () => {
    await form.validateFields();
  }, [form]);

  const handleGetCaptchaClick = useCallback(async () => {
    const values = await form.validateFields(["email"]);
    await loginApis.getCode(values.email);
  }, [form]);

  const registerHandle = useCallback(async () => {
    try {
      setIsloading(true);
      const values = await form.validateFields();
      const rep = await loginApis.register(values);
      if (rep.status === 200 && rep.data.code === 200) {
        message.success("register success!");
        navigate("/login");
      } else {
        message.error(rep.data.data || rep.data.msg);
      }
      setIsloading(false);
    } catch (e) {
      setIsloading(false);
      console.log(e);
    }
  }, [form, navigate]);

  return (
    <Spin spinning={isLoading} tip="Loading...">
      <RightContainer>
        <FormContainer>
          <FormContainer>
            <FormHeader>Register</FormHeader>
            <FormC
              form={form}
              name="control-hooks"
              autoComplete="off"
              validateTrigger={false}
              onFinish={handleFinish}
            >
              <InputFormItem
                name="email"
                validateTrigger={["onBlur"]}
                rules={[
                  { required: true },
                  {
                    type: "email",
                    message: "email address",
                  },
                ]}
              >
                <Input placeholder="valid email" />
              </InputFormItem>
              <InputFormItem name="code" rules={[{ required: true }]}>
                <Row>
                  <Col flex="1">
                    <Form.Item
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "email captcha",
                        },
                      ]}
                    >
                      <Input placeholder="email captcha" />
                    </Form.Item>
                  </Col>
                  <Col flex="none" offset={1}>
                    <CountDownButton
                      number={COUNT_DOWN_SECONDS}
                      loading={false}
                      disabled={false}
                      onClick={handleGetCaptchaClick}
                    >
                      {(isCountDown, countDownNumber) => {
                        return isCountDown
                          ? `Resend after ${countDownNumber} seconds`
                          : "Get captcha";
                      }}
                    </CountDownButton>
                  </Col>
                </Row>
              </InputFormItem>
              <InputFormItem name="organization" validateTrigger={["onBlur"]}>
                <Input placeholder="your organization" />
              </InputFormItem>
              <InputFormItem
                name="password"
                validateTrigger={["onChange"]}
                rules={[
                  { required: true },
                  {
                    pattern: LOGIN_PASSWORD_PATTERN,
                    max: 8,
                    message: "Incorrect password",
                  },
                ]}
              >
                <Input
                  autoComplete="new-password"
                  type="password"
                  placeholder="Please enter password"
                />
              </InputFormItem>
              <InputFormItem
                name="confirmPassword"
                dependencies={["password"]}
                validateTrigger={["onChange"]}
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please keep the same password.")
                      );
                    },
                  }),
                ]}
                validateFirst
              >
                <Input
                  type="password"
                  autoComplete="new-password"
                  placeholder="Please enter password again."
                />
              </InputFormItem>
              <Form.Item>
                <Row align="middle">
                  <Col flex="60%">
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                      onClick={registerHandle}
                    >
                      Register
                    </Button>
                  </Col>
                  <ToLoginContainer flex="40%">
                    <Link to={"/login"} replace>
                      Back to login
                    </Link>
                  </ToLoginContainer>
                </Row>
              </Form.Item>
            </FormC>
          </FormContainer>
        </FormContainer>
      </RightContainer>
    </Spin>
  );
}

export default RegisterComponent;
